import Loading from '@app/components/Loading';
import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo } from '@app/models/enums';
import { IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { IRegistrarMantenimiento } from '@app/models/herramienta/IMantenimiento';
import { FormularioMatenimientoProps } from '@app/models/types/formularioMantenimiento.type';
import { getCatalogo, getHerramienta } from '@app/services';
import { agregarMantenimiento } from '@app/services/herramienta.service';
import { nuevoMantenimientoValidaciones } from '@app/utils/validaciones/mantenimiento.validaciones';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';

export default function FormularioMantenimiento({ idHerramienta, respuestaFormulario }: FormularioMatenimientoProps) {
  const [tiposMantenimiento, setTiposMantenimiento] = useState<ICatalogoGenerico[]>([]);
  const [proveedores, setProveedores] = useState<ICatalogoGenerico[]>([]);
  const [herramienta, setHerramienta] = useState<Partial<IHerramienta>>({
    id: 0,
    numeroControl: '',
    modelo: '',
    herramientaGenerica: '',
    estado: '',
    ubicacion: '',
    responsable: '',
    mantenimiento: '',
    ultimaCertificacion: '',
    marca: '',
    torque: '0',
    unidadMedida: '',
    velocidadMotor: '',
    ultimoMantenimiento: '',
    proximaCertificacion: '',
    proximoMantenimiento: '',
    totalCorrectivos: 0,
    mantenimientos: [],
    certificaciones: [],
  });
  const [cargando, setCargando] = useState<{ estado: boolean; mensaje: string }>({ estado: false, mensaje: '' });
  const { setError, ErrorAlert } = useError();
  const initialValues: IRegistrarMantenimiento = {
    herramientaId: 0,
    fechaMantenimiento: '',
    proveedorId: 0,
    tipoDeMantenimientoId: 0,
    comentarios: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: nuevoMantenimientoValidaciones,
    onSubmit: (values: IRegistrarMantenimiento) => registrarMantenimiento({ ...values, herramientaId: idHerramienta }),
  });

  const registrarMantenimiento = async (valores: IRegistrarMantenimiento) => {
    try {
      setCargando({ estado: true, mensaje: 'Registrando mantenimiento' });
      const data = await agregarMantenimiento(valores);
      respuestaFormulario(data);
      formik.resetForm();
    } catch (ex) {
      const { error } = ex;
      setError(error?.response?.data);
    } finally {
      setCargando({ estado: false, mensaje: '' });
      obtenerHerramienta();
    }
  };

  const obtenerTipoMantenimiento = async () => {
    try {
      const response = await getCatalogo(Catalogo.TiposDeMantenimiento);
      setTiposMantenimiento(response);
    } catch (ex) {
      console.log(ex);
    }
  };

  const obtenerHerramienta = async () => {
    try {
      setCargando({ estado: true, mensaje: 'Buscando herramienta' });
      const response = await getHerramienta(idHerramienta);
      setHerramienta(response);
      await formik.setValues(
        val => ({
          ...val,
        }),
        false
      );
    } catch (ex) {
      console.log(ex);
    } finally {
      setCargando({ estado: false, mensaje: '' });
    }
  };

  const obtenerProveedores = async () => {
    try {
      const data = await getCatalogo(Catalogo.Proveedores);
      setProveedores(data);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    obtenerTipoMantenimiento();
    obtenerHerramienta();
    obtenerProveedores();
  }, []);

  useEffect(() => {
    return () => {
      setTiposMantenimiento([]);
      setCargando({ estado: false, mensaje: '' });
    };
  }, []);

  return (
    <Container className="d-flex flex-column" fluid>
      {cargando.estado && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Alert className="p-2" variant="info">
            <p className="text-left">
              <span className="fw-bold">Herramienta:</span> {herramienta.modelo}
            </p>
            <p className="text-left">
              <span className="fw-bold">Anterior Mantenimiento:</span>{' '}
              {herramienta?.ultimoMantenimiento ? format(new Date(herramienta.ultimoMantenimiento), 'dd/MM/yyyy') : ''}
            </p>
            <p className="text-left">
              <span className="fw-bold">Próximo Mantenimiento:</span>{' '}
              {herramienta?.proximoMantenimiento
                ? format(new Date(herramienta.proximoMantenimiento), 'dd/MM/yyyy')
                : ''}
            </p>
            <p className="text-left">
              <span className="fw-bold">Matenimientos Correctivos:</span> {herramienta.totalCorrectivos}
            </p>
          </Alert>
        </Col>
      </Row>
      <form onSubmit={formik.handleSubmit}>
        <Row className="my-2">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Proveedor Externo</Form.Label>
              <Form.Control
                name="proveedorId"
                isInvalid={!!formik.errors.proveedorId}
                value={formik.values.proveedorId}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={0}>Seleccione Proveedor Externo</option>
                {proveedores.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.proveedorId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-2">
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Tipo de Mantenimiento</Form.Label>
              <Form.Control
                name="tipoDeMantenimientoId"
                isInvalid={!!formik.errors.tipoDeMantenimientoId}
                value={formik.values.tipoDeMantenimientoId}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={0}>Seleccione Tipo de Mantenimiento</option>
                {tiposMantenimiento.map((mant: ICatalogoGenerico) => (
                  <option key={mant.id} value={mant.id}>
                    {mant.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.tipoDeMantenimientoId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Fecha Mantenimiento</Form.Label>
              <Form.Control
                name="fechaMantenimiento"
                isInvalid={!!formik.errors.fechaMantenimiento}
                value={formik.values.fechaMantenimiento}
                onChange={formik.handleChange}
                type="date"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.fechaMantenimiento}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Comentarios</Form.Label>
              <Form.Control
                name="comentarios"
                isInvalid={!!formik.errors.comentarios}
                value={formik.values.comentarios}
                onChange={formik.handleChange}
                as="textarea"
                rows={5}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.comentarios}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="d-flex align-items-end justify-content-end" md={12} sm={12} xs={12}>
            <Button variant="secondary" type="submit">
              Guardar Mantenimiento
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
