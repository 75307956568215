import { IAsyncData } from '@app/models';
import { IItemCertificacion } from '@app/models/herramienta/ICertificacion';
import { IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { getCertificaciones } from '@app/services';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import FormularioCertificaciones from '../FormularioCertificaciones';
import { ROLES } from '@app/models/enums';

export type ListaHerramientaModalProps = {
  show: boolean;
  herramienta: Partial<IHerramienta>;
  onClose?: () => void;
};

export default function CertificacionesModal({ herramienta, show, onClose }: ListaHerramientaModalProps) {
  const roleUsuario = JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase();
  const [tabActiva, setTabActiva] = useState<'lista' | 'formulario'>('lista');
  const [certificaciones, setCertificaciones] = useState<IAsyncData<IItemCertificacion[]>>({ status: 'idle' });

  function closeModal() {
    setCertificaciones({ status: 'idle', data: [] });
    onClose();
  }

  function updateList() {
    loadCertificaciones(herramienta.id);
    setTabActiva('lista');
  }

  function loadCertificaciones(id: number) {
    setCertificaciones({ status: 'pending' });
    getCertificaciones(id)
      .then(res => {
        setCertificaciones({ status: 'fulfilled', data: res });
      })
      .catch(err => {
        console.error(err);
        setCertificaciones({ status: 'rejected' });
      });
  }

  useEffect(() => {
    if (herramienta.id) {
      loadCertificaciones(herramienta.id);
    }
  }, [herramienta.id]);

  useEffect(() => {
    setTabActiva('lista');
  }, [show]);

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Certificaciones {herramienta.modelo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          className="mt-2 w-100"
          onSelect={(val: 'lista' | 'formulario') => setTabActiva(val)}
          activeKey={tabActiva}
          transition={true}
        >
          <Tab className="mt-3" eventKey="lista" title="Lista de certificaciones">
            <Table striped hover>
              <thead>
                <tr>
                  <th>Proveedor Externo</th>
                  <th>Fecha certificación</th>
                </tr>
              </thead>
              <tbody>
                {certificaciones.data?.length ? (
                  certificaciones.data.map(cert => (
                    <tr key={cert.id}>
                      <td>{cert.responsable}</td>
                      <td>{format(new Date(cert.ultimaCertificacion), 'dd/MM/yyyy')}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>No hay certificaciones</td>
                  </tr>
                )}
                {certificaciones.status === 'pending' && (
                  <tr>
                    <td colSpan={2}>Cargando certificaciones...</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Tab>
          {ROLES.ADMINISTRADOR === roleUsuario && (
            <Tab className="mt-3" eventKey="formulario" title="Agregar certificación">
              <FormularioCertificaciones
                idHerramienta={herramienta.id}
                respuestaFormulario={updateList}
                herramienta={herramienta}
                certificaciones={certificaciones.data}
              />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
