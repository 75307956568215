import Loading from '@app/components/Loading';
import EditarResguardoModal from '@app/components/modals/EditarResguardoModal';
import Title from '@app/components/Title';
import useError from '@app/hooks/useError';
import classNames from 'classnames';
import { IPrestamo, IResguardo } from '@app/models/prestamos/IPrestamo';
import { getMovimientos, getMovimientosFilter } from '@app/services/movimientos.service';
import { getReporteHerramientasHistorialService } from '@app/services/reportes.service';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { descargarArchivo } from '../../utils/descargarArchivo';
import { ROLES } from '@app/models/enums';

export default function PrestamoHistorico() {
  const [prestamos, setPrestamos] = useState<IPrestamo[]>([]);
  const [prestamosAux, setPrestamosAux] = useState<IPrestamo[]>([]);
  const [cargando, setCargando] = useState<boolean>(false);
  const [descargandoReporte, setDescargandoReporte] = useState<boolean>(false);
  const [paginacion, setPaginacion] = useState<{ numeroPagina: number; paginas: number; masRegistros: boolean }>({
    numeroPagina: 1,
    paginas: 25,
    masRegistros: true,
  });
  const [modalResguardo, setModalResguardo] = useState<boolean>(false);
  const [herramientasMovimiento, setHerramientasMovimiento] = useState<IPrestamo[]>([]);
  const [herramientaSeleccionada, setHerramientaSeleccionada] = useState<{
    idHerramienta: number;
    idMovimiento: number;
  }>({
    idHerramienta: 0,
    idMovimiento: 0,
  });
  const [queryBusqueda, setQueryBusqueda] = useState<string>('');
  const [roleActivo, setRoleActivo] = useState<string>('');
  const { setError, ErrorAlert } = useError();

  const obtenerPrestamos = async (numeroPagina = paginacion.numeroPagina, paginas = paginacion.paginas) => {
    try {
      if (numeroPagina === 1) {
        setCargando(true);
        setPrestamos([]);
        setPrestamosAux([]);
        setPaginacion({
          numeroPagina: 1,
          paginas: 25,
          masRegistros: false,
        });
      }
      const response = await getMovimientos(numeroPagina, paginas);
      response.forEach(item => {
        item.fecha = new Date(item.fecha);
      });
      setPrestamos(prest => [...prest, ...response]);
      setPrestamosAux(prest => [...prest, ...response]);
      setPaginacion(paginacion => ({
        ...paginacion,
        numeroPagina: paginacion.numeroPagina + 1,
        masRegistros: !!response.length,
      }));
    } catch (ex) {
      setError(ex?.response?.data);
    } finally {
      setCargando(false);
    }
  };

  const buscarPrestamo = async (filter: string = '', pagina: number = 1, cantidadPaginas: number = 25) => {
    try {
      if (pagina === 1) {
        setCargando(true);
        setPrestamos([]);
        setPrestamosAux([]);
        setPaginacion({
          numeroPagina: 1,
          paginas: 25,
          masRegistros: false,
        });
      }
      const response = await getMovimientosFilter(filter, pagina, cantidadPaginas);
      setPrestamos(prest => [...prest, ...response]);
      setPrestamosAux(prest => [...prest, ...response]);
      setPaginacion(paginacion => ({
        ...paginacion,
        numeroPagina: paginacion.numeroPagina + 1,
        masRegistros: !!response.length,
      }));
    } catch (ex) {
      setError(ex?.response?.data);
    } finally {
      setCargando(false);
    }
  };

  const recuperarResguardoEditado = (nuevoResguardo: IResguardo[]) => {
    const herramientasResguardoEditadas: IPrestamo[] = prestamos.map((mv: IPrestamo) => {
      const encontrado = nuevoResguardo.find(hr => hr.idHerramienta === mv.idHerramienta);
      return encontrado ? { ...mv, resguardo: encontrado.resguardo } : mv;
    });
    const herramientasResguardoEditadasAux: IPrestamo[] = prestamosAux.map((mv: IPrestamo) => {
      const encontrado = nuevoResguardo.find(hr => hr.idHerramienta === mv.idHerramienta);
      return encontrado ? { ...mv, resguardo: encontrado.resguardo } : mv;
    });
    setModalResguardo(false);
    setPrestamos(herramientasResguardoEditadas);
    setPrestamosAux(herramientasResguardoEditadasAux);
  };

  const descargarReporte = async () => {
    try {
      setDescargandoReporte(true);
      const response = await getReporteHerramientasHistorialService();
      descargarArchivo(response, 'ReporteHistorialPrestamos.xlsx');
    } catch (ex) {
      setError(ex);
    } finally {
      setDescargandoReporte(false);
    }
  };

  //i Retornamos un booleano dependiendo de los días transcurridos y si el estado de la herramienta es "prestada"
  const tieneEntregaRetasada = (diasTranscurridos: number, estadoHerramienta: boolean): boolean =>
    diasTranscurridos > 5 && !estadoHerramienta;

  useEffect(() => {
    setCargando(true);
    obtenerPrestamos();
    setRoleActivo(JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase());
    return () => {
      setPrestamos([]);
      setPrestamosAux([]);
      setDescargandoReporte(false);
      setError('');
      setPaginacion({
        numeroPagina: 1,
        paginas: 25,
        masRegistros: true,
      });
      setCargando(false);
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <ErrorAlert />
      <Title titulo="Historial Préstamos de Herramienta" ruta="" />
      <Row className="mb-5">
        <Col xs={12} sm={5} md={5} lg={5}>
          <InputGroup>
            <Form.Control
              name="prestamo"
              onChange={({ target }) => setQueryBusqueda(target.value)}
              placeholder="Buscar Préstamo (No.Trabajador/Responsable/Herramienta)"
              type="search"
            />
            <Button
              onClick={() => {
                if (queryBusqueda) {
                  buscarPrestamo(queryBusqueda, 1, 25);
                } else {
                  obtenerPrestamos(1, 25);
                }
              }}
            >
              <SearchIcon />
            </Button>
          </InputGroup>
        </Col>
        {roleActivo !== ROLES.USUARIOEXTERNO && (
          <Col xs={12} sm={7} md={7} lg={7} className="d-flex align-items-center justify-content-end">
            <Button variant="secondary" onClick={() => descargarReporte()} disabled={descargandoReporte}>
              {descargandoReporte ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  &nbsp;Descargando Reporte
                </>
              ) : (
                'Descargar Reporte'
              )}
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col x={12} sm={12} md={12} lg={12}>
          <InfiniteScroll
            className="mt-5"
            dataLength={prestamos.length}
            next={() =>
              !queryBusqueda
                ? obtenerPrestamos(paginacion.numeroPagina, paginacion.paginas)
                : buscarPrestamo(queryBusqueda, paginacion.numeroPagina, paginacion.paginas)
            }
            height={550}
            loader={
              <>
                {!cargando && (
                  <>
                    {paginacion.masRegistros && prestamos.length > paginacion.paginas && (
                      <div className="d-flex align-items-center justify-content-center py-4">
                        <Spinner variant="secondary" animation="border" />
                        &nbsp;Cargando Prestamos
                      </div>
                    )}
                  </>
                )}
              </>
            }
            hasMore={paginacion.masRegistros}
          >
            {cargando ? (
              <Loading mensaje="Cargando historial de préstamos" />
            ) : (
              <Table hover bordered>
                <thead>
                  <tr>
                    <th>No.Trabajador</th>
                    <th>Responsable</th>
                    <th>Fecha/Hora Prestamo</th>
                    <th>Días Transcurridos</th>
                    <th>Días Prestada</th>
                    <th>NC</th>
                    <th>Herramienta</th>
                    <th>Complemento Nombre</th>
                    <th>Tipo Herramienta</th>
                    <th>Movimiento</th>
                    <th>Resguardo</th>
                    <th>Responsable Taller</th>
                    <th>Observaciones</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {prestamos.length ? (
                    prestamos.map((pres: IPrestamo) => {
                      const esRetrasado = tieneEntregaRetasada(pres.diasTranscurridos, pres.entregado) && !pres.resguardo;
                      return (
                        <tr
                          key={pres.id}
                          className={classNames({
                            'fondo-retrasado': esRetrasado,
                            'fondo-transparente': !esRetrasado,
                          })}
                        >
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.numeroTarjeta}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.nombreEmpleado}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {format(new Date(pres.fecha), 'dd/MM/yyyy h:mm aa')}
                          </td>
                          <td className={classNames('texto-negro texto-derecha', { 'texto-blanco': esRetrasado })}>
                            {pres.resguardo ? '' : pres.diasTranscurridos || ''}
                          </td>
                          <td className={classNames('texto-negro texto-derecha', { 'texto-blanco': esRetrasado })}>
                            {pres.resguardo ? '' : pres.diasPrestada || ''}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.nc}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.nombre}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.complementoNombre}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.tipoHerramienta}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.tipoMovimiento}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {!pres.entregado ? (pres.resguardo ? 'Si' : 'No') : ''}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.registroUsuario}
                          </td>
                          <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                            {pres.observaciones ?? 'Sin observaciones'}
                          </td>
                          <td>
                            {pres.tipoMovimiento.toLowerCase() === 'salida' && (
                              <IconButton
                                onClick={() => {
                                  setModalResguardo(true);
                                  const herramientasDeMovimiento = prestamos.filter(
                                    (mov: IPrestamo) => mov.idMovimiento === pres.idMovimiento
                                  );
                                  setHerramientaSeleccionada({
                                    idHerramienta: pres.id,
                                    idMovimiento: pres.idMovimiento,
                                  });
                                  setHerramientasMovimiento(herramientasDeMovimiento);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={14}>No hay prestamos registrados.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </InfiniteScroll>
        </Col>
      </Row>
      <EditarResguardoModal
        estadoModal={modalResguardo}
        cambiarEstadoModal={setModalResguardo}
        herramientas={herramientasMovimiento}
        herramientaSeleccionada={herramientaSeleccionada}
        recuperarResguardo={recuperarResguardoEditado}
      />
    </Container>
  );
}
