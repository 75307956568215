import { AppRoutes } from '@app/contants/app-routes';
import styles from '@app/styles/pages/Herramienta.module.scss';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

export default function Prestamos() {
  const location = useLocation();

  return (
    <>
      <Container className={styles.miniNav} fluid>
        <Link
          to="recientes"
          className={classNames(styles.miniNavLink, {
            [styles.active]: location.pathname === AppRoutes.PrestamosRecientes,
          })}
        >
          <EngineeringIcon fontSize="medium" />
          &nbsp;Recientes
        </Link>
        <Link
          to="incompletos"
          className={classNames(
            styles.miniNavLink,
            {
              [styles.active]: location.pathname === AppRoutes.PrestamosIncompletos,
            },
            'mx-3'
          )}
        >
          <PendingActionsIcon fontSize="medium" />
          &nbsp;Pendientes
        </Link>
        <Link
          to="historico"
          className={classNames(styles.miniNavLink, {
            [styles.active]: location.pathname === AppRoutes.PrestamosHistorico,
          })}
        >
          <ManageSearchIcon fontSize="medium" />
          &nbsp;Historial
        </Link>
      </Container>

      <Outlet />
    </>
  );
}
