import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import Title from '../../components/Title';
import { AppRoutes } from '../../contants/app-routes';
import useError from '../../hooks/useError';
import { ICargando } from '../../models/ICargando';
import { IRegistroUsuario } from '../../models/usuarios/IRegistroUsuario';
import { IRol, IUsuario, IUsuarioInterno } from '../../models/usuarios/IUsuario';
import {
  addUsuarioExternoService,
  addUsuarioIternoService,
  editUsuariosService,
  getRolesService,
  getUsuariosInternosService,
  getUsuariosService,
} from '../../services/usuarios.service';
import { registroUsuarioValidationSchema } from '../../utils/validaciones/registro-usuario.validaciones';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/pages/RegistroUsuarios.scss';

export default function RegistroUsuario() {
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const [roles, setRoles] = useState<IRol[]>([]);
  const [usuariosInternos, setUsuariosInternos] = useState<IUsuarioInterno[]>([]);
  const [seleccionado, setSeleccionado] = useState<IUsuarioInterno[]>([]);
  const [tipoUsuario, setTipoUsuario] = useState<string>('');
  const navigate = useNavigate();
  const { id, tipo } = useParams();
  const { ErrorAlert, setError } = useError();

  const initialValues: IRegistroUsuario = {
    userName: '',
    email: '',
    roles: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registroUsuarioValidationSchema,
    onSubmit: values => {
      registrarUsuario({ ...values, roles: values.roles.map(role => ({ name: role, selected: true })) });
    },
  });

  const registrarUsuario = async (dataRequest: IRegistroUsuario) => {
    try {
      setCargando({
        cargando: true,
        mensaje: !!id ? 'Editando Usuario' : 'Guardando Usuario',
      });
      if (!!id) {
        await editUsuariosService(dataRequest, id);
      } else {
        tipo?.toLowerCase() === 'interno'
          ? await addUsuarioIternoService(dataRequest)
          : await addUsuarioExternoService(dataRequest);
      }
      navigate(AppRoutes.Usuarios);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const obtenerRoles = async () => {
    try {
      const response = await getRolesService();
      setRoles(response);
    } catch (ex) {
      setError(ex);
    }
  };

  const obtenerUsuariosInternos = async () => {
    try {
      const response = await getUsuariosInternosService();
      setUsuariosInternos(response);
    } catch (ex) {
      setError(ex);
    }
  };

  const obtenerDetalleUsuario = async () => {
    try {
      const usuarios = await getUsuariosService();
      const detalleUsuario: IUsuario = usuarios.find(usuario => usuario.id === id);

      //i Se quitan los roles externos e internos de la lista
      const rolesNuevos = [...detalleUsuario.roles]
        .filter((role: IRol) => role.name !== 'UsuarioInterno' && role.name !== 'UsuarioExterno')
        .map(item => item.name);

      setTipoUsuario(detalleUsuario.tipo.toLowerCase());
      formik.setFieldValue('userName', detalleUsuario?.userName);
      formik.setFieldValue('email', detalleUsuario?.email);
      formik.setFieldValue('roles', [...rolesNuevos]);
      // detalleUsuario?.roles.map(role => {
      //   document.querySelector(`#${role.name.toLowerCase()}`)?.setAttribute('checked', 'checked');
      // });
    } catch (ex) {
      setError(ex);
    }
  };

  const usuarioInternoSeleccionado = (usuario: IUsuarioInterno[]) => {
    setSeleccionado([...usuario]);
    if (!!usuario.length) {
      const [usrInterno] = usuario;
      formik.setFieldValue('userName', usrInterno.userName);
      formik.setFieldValue('email', usrInterno.email);
    }
  };

  const capitalizeTipo = () => (!!tipo ? tipo.charAt(0).toUpperCase().concat(tipo.slice(1)) : '');

  useEffect(() => {
    obtenerRoles();
    obtenerUsuariosInternos();
    if (!!id) {
      obtenerDetalleUsuario();
    }
    return () => {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    };
  }, []);

  return (
    <Container className="registro-usuario d-flex flex-column mt-5">
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Title
        titulo={!!id ? `Editar Usuario ${capitalizeTipo()}` : `Registro de Usuario ${capitalizeTipo()}`}
        ruta={AppRoutes.Usuarios}
      />
      <form className="mt-5" onSubmit={formik.handleSubmit}>
        {!!!id && tipo?.toLowerCase() === 'interno' && (
          <Row className="my-4">
            <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
              <Form.Group>
                <Form.Label htmlFor="single-typeahead">Buscar Usuario</Form.Label>
                <Typeahead
                  id="single-typeahead"
                  clearButton
                  filterBy={['userName', 'nombre', 'email']}
                  labelKey="userName"
                  onChange={usuarioInternoSeleccionado}
                  options={usuariosInternos}
                  selected={seleccionado}
                  renderMenuItemChildren={(option: IUsuarioInterno, { text }) => (
                    <div>
                      <Highlighter highlightClassName="bg-transparent font-weight-bold p-0" search={text}>
                        {option.nombre}
                      </Highlighter>
                      <div>
                        <div>
                          <small>
                            Usuario:{' '}
                            <Highlighter highlightClassName="bg-transparent font-weight-bold p-0" search={text}>
                              {option.userName}
                            </Highlighter>
                          </small>
                        </div>
                        <div>
                          <small>
                            Email:{' '}
                            <Highlighter highlightClassName="bg-transparent font-weight-bold p-0" search={text}>
                              {option.email}
                            </Highlighter>
                          </small>
                        </div>
                      </div>
                    </div>
                  )}
                  placeholder={`Usuario ${capitalizeTipo()}`}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label>
                Usuario<span className="registro-usuario__required">*</span>
              </Form.Label>
              <Form.Control
                name="userName"
                isInvalid={!!formik.errors.userName}
                value={formik.values.userName}
                onChange={formik.handleChange}
                placeholder="Usuario"
                readOnly={tipo?.toLowerCase() === 'interno' || !!id}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.userName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
            <Form.Group>
              <Form.Label>
                Correo Electronico
                <span className="registro-usuario__required">*</span>
              </Form.Label>
              <Form.Control
                name="email"
                isInvalid={!!formik.errors.email}
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Correo Electronico"
                readOnly={tipo?.toLowerCase() === 'interno' || (tipoUsuario === 'interno' && !!id)}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <>
          {roles.length && tipo?.toLowerCase() === 'interno' ? (
            <Row className="my-4">
              <Col className="offset-lg-3 offset-md-3" xs={12} sm={12} md={6} lg={6}>
                <Form.Group>
                  <Form.Label className="m-0">Roles</Form.Label>

                  <>
                    {roles.map((role: IRol) => (
                      <Form.Check
                        key={role.id}
                        className="my-3"
                        type="checkbox"
                        id={role.name.toLowerCase()}
                        label={role.name}
                        isInvalid={!!formik.errors.roles}
                        name="roles"
                        checked={formik.values.roles.some(item => item.toLowerCase() === role.name.toLowerCase())}
                        value={role.name}
                        onChange={formik.handleChange}
                      />
                    ))}
                  </>
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {formik.errors.roles}
                  </Form.Control.Feedback>
                </Form.Group>
                <p className="mt-4">
                  Los campos con <span className="registro-usuario__required">*</span> son obligatorios.
                </p>
              </Col>
            </Row>
          ) : null}
        </>
        <Row className="my-4">
          <Col
            className="offset-lg-3 offset-md-3 my-0 d-flex align-items-center justify-content-center"
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Button className="text-white w-75" type="submit" variant="secondary">
              Guardar Usuario {capitalizeTipo()}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
