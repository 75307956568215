import useError from '@app/hooks/useError';
import { ICatalogo } from '@app/models/catalogos/ICatalogo';
import { Catalogo } from '@app/models/enums';
import { nuevoCatalogoValidaciones, proveedorSchema } from '@app/utils/validaciones/catalogos.validaciones';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

type AgregarCatalogoModalProps = {
  estadoModal: boolean;
  cambiarEstadoModal: Function;
  tituloModal: string;
  respuestaModal?: Function;
  dataModal?: ICatalogo;
  catalogo: Catalogo;
};

export default function AgregarCatalogoModal({
  estadoModal,
  cambiarEstadoModal,
  tituloModal,
  dataModal,
  respuestaModal,
  catalogo,
}: AgregarCatalogoModalProps) {
  const { setError, ErrorAlert } = useError();
  const initialValues: Partial<ICatalogo> = {
    id: undefined,
    descripcion: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: catalogo === Catalogo.Proveedores ? proveedorSchema : nuevoCatalogoValidaciones,
    onSubmit: (values: ICatalogo) => agregarCatalogo(values),
  });

  const agregarCatalogo = (data: ICatalogo) => {
    try {
      if (dataModal.id) {
        respuestaModal({ ...dataModal, descripcion: data.descripcion });
        cambiarEstadoModal(false);
      } else {
        respuestaModal({ id: data.id, descripcion: data.descripcion });
        cambiarEstadoModal(false);
      }
    } catch (ex) {
      setError(ex?.response?.data);
    }
  };

  useEffect(() => {
    formik.resetForm();
    
    if (estadoModal) {
      if (dataModal.id) {
        formik.setFieldValue('descripcion', dataModal.descripcion, false);
        formik.setFieldValue('id', dataModal.id, false);
      }
    }
  }, [estadoModal]);

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{tituloModal}</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <ErrorAlert/>
          {catalogo === Catalogo.Proveedores && !dataModal.id && (
            <Form.Group>
              <Form.Label>Código de Proveedor</Form.Label>
              <Form.Control
                name="id"
                isInvalid={!!formik.errors.id}
                onChange={formik.handleChange}
                value={formik.values.id}
                placeholder="Código de Proveedor"
                autoFocus
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.id}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              name="descripcion"
              isInvalid={!!formik.errors.descripcion}
              onChange={formik.handleChange}
              value={formik.values.descripcion}
              placeholder="Descripción"
              autoFocus
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {formik.errors.descripcion}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Guardar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
