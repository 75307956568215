import { modalEliminarProps } from '@app/models/types/modal.type';
import { Button, Modal } from 'react-bootstrap';

export default function EliminarModal(props: modalEliminarProps) {
  const { estadoModal, cambiarEstadoModal, tituloModal, nombreItemModal, respuestaModal } = props;
  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{tituloModal}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="fw-normal">
          ¿Está seguro que desea eliminar <span className="fw-bold">{nombreItemModal}</span>?
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => respuestaModal()}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
