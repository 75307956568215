import { ISesionContext } from '@app/models/login/ISesionContext';
import { createContext } from 'react';

const SesionContext = createContext<ISesionContext>({
  usuario: null,
  setUsuario: Function,
  datosTrabajadorES: {
    codigocol: 0,
    correo: '',
    departamento: '',
    empresa: '',
    gerencia: '',
    nivelFirma: '',
    nombre: '',
    pueId: 0,
    puesto: '',
    tipo: '',
    unoId: 0,
    usuarioId: '',
    tarjeta: 0,
  },
  setDatosTrabajadorEs: Function,
});

export default SesionContext;
