import useError from '@app/hooks/useError';
import { IPrestamo, IResguardo } from '@app/models/prestamos/IPrestamo';
import { modalEditarResguardoProps } from '@app/models/types/modal.type';
import { putPrestamosResguardo } from '@app/services/movimientos.service';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default function EditarResguardoModal(props: modalEditarResguardoProps) {
  const { estadoModal, cambiarEstadoModal, herramientas, herramientaSeleccionada, recuperarResguardo } = props;
  const [herramienta, setHerramienta] = useState<IPrestamo>(null);
  const [listaHerramientas, setListaHerramientas] = useState<IPrestamo[]>([]);
  const [herramientasResguardo, setHerramientasResguardo] = useState<IResguardo[]>([]);
  const { setError, ErrorAlert } = useError();

  const guardarResguardos = async () => {
    try {
      const response = await putPrestamosResguardo(herramientaSeleccionada.idMovimiento, herramientasResguardo);
      recuperarResguardo(response);
    } catch (ex) {
      setError(ex);
    }
  };

  const herramientaEditada = (idHerramienta: number, resguardo: boolean) => {
    setHerramientasResguardo((res: IResguardo[]) =>
      res.map((rs: IResguardo) =>
        rs.idHerramienta === idHerramienta ? { idHerramienta: rs.idHerramienta, resguardo: resguardo } : rs
      )
    );
  };

  const formateoFecha = (fecha: any) => {
    if (fecha) {
      return format(new Date(fecha), 'dd/MM/yyyy hh:mm aaa');
    }
    return '';
  };

  const buscarResguardo = (idHerramienta: number) => {
    if (estadoModal) {
      return herramientasResguardo.find(hr => hr.idHerramienta === idHerramienta)?.resguardo;
    }
  };

  useEffect(() => {
    if (estadoModal && herramientas.length) {
      const herramientaEncontrada = herramientas.find(
        (her: IPrestamo) => her.id === herramientaSeleccionada.idHerramienta
      );
      setHerramienta(herramientaEncontrada);
      const lista = herramientas.filter(
        (herra: IPrestamo) => herra.idHerramienta !== herramientaEncontrada.idHerramienta
      );
      setListaHerramientas(lista);
      const listaResguardos: IResguardo[] = herramientas.map((hr: IPrestamo) => ({
        idHerramienta: hr.idHerramienta,
        resguardo: hr.resguardo,
      }));
      setHerramientasResguardo(listaResguardos);
    }
    if (!estadoModal) {
      setError('');
    }
  }, [estadoModal]);

  useEffect(() => {
    return () => {
      setHerramienta(null);
      setHerramientasResguardo([]);
      setListaHerramientas([]);
      setError('');
    };
  }, []);

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Resguardo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorAlert />
        <p className="my-2">
          Fecha/Hora Prestamo: <span className="fw-bold">{formateoFecha(herramientas[0]?.fecha) || ''}</span>
        </p>
        <p className="my-2">
          No.Trabajador: <span className="fw-bold">{herramientas[0]?.numeroTarjeta || ''}</span>
        </p>
        <p className="my-2">
          Responsable: <span className="fw-bold">{herramientas[0]?.nombreEmpleado || ''}</span>
        </p>
        <p className="my-2">Cambiar el estado de resguardo:</p>
        <div className="d-flex align-items-center justify-content-between">
          <p className="my-2 fw-bold">
            NC: {herramienta?.nc || ''} - Herramienta: {herramienta?.nombre || ''}
          </p>
          <Form.Check
            className="my-2 mx-2"
            type="checkbox"
            name="resguardo"
            checked={buscarResguardo(herramienta?.idHerramienta)}
            onChange={({ target }) => {
              herramientaEditada(herramienta?.idHerramienta, target.checked);
            }}
          />
        </div>
        <p className="my-2">Herramientas que salieron en el mismo movimiento:</p>
        {listaHerramientas.map((herra: IPrestamo) => {
          return (
            <div className="d-flex align-items-center justify-content-between">
              <p className="my-2 fw-bold" key={herra.id}>
                NC: {herra.nc} - Herramienta: {herra.nombre}
              </p>
              <Form.Check
                className="my-2 mx-2"
                type="checkbox"
                name="resguardo"
                checked={buscarResguardo(herra.idHerramienta)}
                onChange={({ target }) => {
                  herramientaEditada(herra.idHerramienta, target.checked);
                }}
              />
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
          Cancelar
        </Button>
        <Button onClick={() => guardarResguardos()}>Guardar</Button>
      </Modal.Footer>
    </Modal>
  );
}
