import { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

import { IHerramientaGenerica } from '@app/models/herramienta';
import { deleteHerramientaGenerica } from '@app/services/herramienta-generica.service';

type EliminarHerramientaModalProps = {
  herramienta: Partial<IHerramientaGenerica>;
  onHide: () => void;
  show: boolean;
  recuperarIdHerramienta: Function;
};

export default function EliminarHerramientGenericaModal({
  herramienta,
  onHide,
  show,
  recuperarIdHerramienta,
}: EliminarHerramientaModalProps) {
  const [numeroControl, setNumeroControl] = useState('');
  const [cargando, setCargando] = useState(false);
  const isInvalid = numeroControl.length > 1 && numeroControl !== herramienta.inicialNC;
  const refInput = useRef(null);

  const eliminarHerramienta = async () => {
    setCargando(true);
    try {
      await deleteHerramientaGenerica(herramienta.id);
      recuperarIdHerramienta(herramienta.id);
      onHide();
    } catch (ex) {
      throw ex;
    } finally {
      setCargando(false);
    }
  };

  useEffect(() => {
    if(show){
      refInput.current.focus();
    }
    else {
      setNumeroControl('');
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Eliminar herramienta genérica</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        ¿Para eliminar la herramienta genérica escriba el número de control <span className='fw-bold'>(NC)</span>?
      </p>
      <Form.Group>
        <Form.Label htmlFor="numeroControl">Confirma escribiendo el número de control <span className='fw-bold'>(NC)</span>:</Form.Label>
        <Form.Control
          id="numeroControl"
          ref={refInput}
          name="numeroControl"
          placeholder={herramienta.inicialNC}
          value={numeroControl}
          onChange={e => setNumeroControl(e.target.value)}
          isInvalid={isInvalid}
        />
        {isInvalid && (
          <Form.Control.Feedback className="d-block" type="invalid">
            El número de control ingresado no es el correcto.
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button type="button" variant="outline-secondary" size="sm" onClick={onHide}>
        Cancelar
      </Button>
      <Button onClick={eliminarHerramienta} type="submit" color="danger" size="sm" disabled={cargando || isInvalid}>
        {cargando ? <Spinner animation="border" /> : 'Eliminar'}
      </Button>
    </Modal.Footer>
  </Modal>
  )
}
