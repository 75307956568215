import { IPrestamo } from '@app/models/prestamos/IPrestamo';
import { getPrestamosPendientesTrabajador } from '@app/services/movimientos.service';
import { baseApi } from '../baseApi';

export const movimientosApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    prestamosPendientesTrabajador: builder.query<IPrestamo[], { codigoEmp: number }>({
      queryFn: async ({ codigoEmp }) => {
        try {
          const data = await getPrestamosPendientesTrabajador(codigoEmp);
          return { data };
        } catch (err) {
          return { error: err };
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { usePrestamosPendientesTrabajadorQuery } = movimientosApi;
