import { loadingProps } from '@app/models/types/loading.types';
import { Spinner } from 'react-bootstrap';

import styles from '@app/styles/components/loading.module.scss';

export default function Loading(props: loadingProps) {
  const { mensaje } = props;

  return (
    <div className={styles.loading}>
      <Spinner className={styles.loadingSpinner} animation="border" variant="secondary" />
      <p className={styles.loadingMensaje}>{mensaje}</p>
    </div>
  );
}
