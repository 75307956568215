import { TitleProps } from '@app/models/types/title.type';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

export default function Title(props: TitleProps) {
  const { ruta, titulo } = props;
  return (
    <Row className="mb-5">
      {!!ruta && (
        <Col md={1} className="d-flex align-items-center">
          <Link to={ruta}>
            <ArrowBack className="text-body" />
          </Link>
        </Col>
      )}
      <Col md={!!ruta ? 10 : 12}>
        <h1 className="text-center m-0">{titulo}</h1>
      </Col>
    </Row>
  );
}
