import { ILogin } from '@app/models/login/ILogin';
import axios from '@app/utils/request/axios';


export const loginService = async (dataRequest: ILogin) => {
  try {
    const { data } = await axios.post('Auth/login', dataRequest);
    localStorage.setItem('credenciales', JSON.stringify(data));
    return { data, error: null };
  }
  catch(ex){
    return { data: null, error: ex };
  }
}
