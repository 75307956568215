import { ErrorMessages } from '@app/contants/errorMessages';
import { number, object, string } from 'yup';

export const herramientaMenorSchema = object().shape({
  numeroControl: string().required(ErrorMessages.Required),
  fechaCompra: string().required(ErrorMessages.Required),
  marca: string().required(ErrorMessages.Required),
  ubicacionId: string().required(ErrorMessages.Required),
  estadoId: string().required(ErrorMessages.Required),
  costo: number().nullable(),
});

export const herramientaSchema = herramientaMenorSchema.shape({
  modelo: string().required(ErrorMessages.Required),
  numeroSerie: string().required(ErrorMessages.Required),
  presion: string().nullable(),
  torque: number().nullable(),
  velocidadMotor: string().nullable(),
  peso: string().nullable(),
});

export const herramientaMecanicaSchema = herramientaSchema.shape({
  unidadMedidaId: string().nullable(),
  rangoCapacidadMaxima: string().nullable(),
  rangoCapacidadMinima: string().nullable(),
});

export const herramientaHidraulicaSchema = herramientaSchema.shape({
  compatibilidadCilindroId: string().required(ErrorMessages.Required),
  capacidadDeposito: number().required(ErrorMessages.Required),
  capacidadDepositoUnidadId: string().required(ErrorMessages.Required),
  flujoMaxPresionNominal: number().required(ErrorMessages.Required),
  flujoMaxPresionNominalUnidadId: string().required(ErrorMessages.Required),
  esfuerzoMaxBombeo: number().nullable(),
  esfuerzoMaxBombeoUnidadId: string().nullable(),
});
