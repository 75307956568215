import axios from '@app/utils/request/axios';

export const getReporteHerramientasService = async (filter: string = '', tipo: number = 0) => {
  try {
    const { data } = await axios.get('ExportExcel/ExportExcelHerramientas', {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      params: {
        filter,
        tipo,
      },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getReporteHerramientasEliminadasService = async (filter: string = '', tipo: number = 0) => {
  try {
    const { data } = await axios.get('ExportExcel/ExportExcelHerramientasEliminadas', {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      params: {
        filter,
        tipo,
      },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getReporteHerramientasPrestamosService = async () => {
  try {
    const { data } = await axios.get('ExportExcel/ExportExcelPrestamos', {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getReporteHerramientasPendientesService = async () => {
  try {
    const { data } = await axios.get('ExportExcel/ExportExcelPrestamosPendientes', {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getReporteHerramientasHistorialService = async () => {
  try {
    const { data } = await axios.get('ExportExcel/ExportExcelHistorialPrestamos', {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};
