import SelectTipoHerramienta from '@app/components/forms/SelectTipoHerramienta';
import Title from '@app/components/Title';
import { AppRoutes } from '@app/contants/app-routes';
import { IAsyncData } from '@app/models';
import { Catalogo } from '@app/models/enums';
import { ICrearHerramientaGenerica } from '@app/models/herramienta';
import { IItemCatalogo } from '@app/models/herramienta/IHerramientaGenerica';
import { editHerramientaGenerica, getCatalogo, postCrearHerramientaGenerica } from '@app/services';
import { getPeriodosDeCertificacion } from '@app/services/catalogos.service';
import { getHerramientaGenericaByIdService } from '@app/services/herramienta-generica.service';
import { herramientaGenericaSchema } from '@app/utils/validaciones/herramientaGenerica.schema';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';


export default function NuevaHerramientaGenerica() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiRequest, setRequestStatus] = useState<IAsyncData<string>>({ status: 'idle', data: null });
  const [periodosCertificacion, setPeriodosCertificacion] = useState<IAsyncData<IItemCatalogo[]>>({
    status: 'pending',
    data: [],
  });
  const [periodosMantenimiento, setPeriodosMantenimiento] = useState<IAsyncData<IItemCatalogo[]>>({
    status: 'pending',
    data: [],
  });
  const formik = useFormik({
    initialValues: {
      nombre: '',
      inicialNc: '',
      tipoHerramientaId: undefined,
      periodoCertificacionId: undefined,
      periodoMantenimientoId: undefined,
    },
    validationSchema: herramientaGenericaSchema,
    onSubmit(values: ICrearHerramientaGenerica) {
      setRequestStatus({ status: 'pending' });
      if(!!!id){
        postCrearHerramientaGenerica(values)
          .then(() => {
            setRequestStatus({ status: 'fulfilled' });
            navigate(AppRoutes.CatalogoAlmacen);
          })
          .catch(err => {
            setRequestStatus({ status: 'rejected', data: err });
          });
      }
      else {
        editHerramientaGenerica(+id, {
          ...values, 
          periodoCertificacionId: +values.periodoCertificacionId, 
          periodoMantenimientoId: +values.periodoMantenimientoId, 
          tipoHerramientaId: +values.tipoHerramientaId
        })
        .then(() => {
          setRequestStatus({ status: 'fulfilled' });
          navigate(AppRoutes.CatalogoAlmacen);
        })
        .catch(err => {
          setRequestStatus({ status: 'rejected', data: err });
        });
      }
    },
  });

  const obtenerDetalleHerramienta = async () => {
    try{
      const response = await getHerramientaGenericaByIdService(+id);
      formik.setFieldValue('nombre', response.nombre, false);
      formik.setFieldValue('inicialNc', response.inicialNC, false);
      formik.setFieldValue('periodoCertificacionId', response.periodoCertificacionId, false);
      formik.setFieldValue('periodoMantenimientoId', response.periodoMantenimientoId, false);
      formik.setFieldValue('tipoHerramientaId', response.tipoHerramientaId, false);
    }
    catch(ex){
      setPeriodosMantenimiento({ status: 'rejected', data: [] });
    }
  }

  useEffect(() => {
    getPeriodosDeCertificacion()
      .then(periodos => {
        setPeriodosCertificacion({ status: 'fulfilled', data: periodos });
      })
      .catch(() => {
        setPeriodosCertificacion({ status: 'rejected', data: [] });
      });

    getCatalogo(Catalogo.PeriodoMantenimiento)
      .then(peridosMant => {
        setPeriodosMantenimiento({ status: 'fulfilled', data: peridosMant });
      })
      .catch(() => {
        setPeriodosMantenimiento({ status: 'rejected', data: [] });
      });
    if(!!id){
      obtenerDetalleHerramienta();
    }
  }, []);

  return (
    <>
      <Container className="d-flex flex-column mt-5 px-5" fluid>
        <Title titulo={`${!!id ? 'Editar' : 'Nueva'} Herramienta Genérica`} ruta={AppRoutes.CatalogoAlmacen} />
        {apiRequest.status === 'rejected' && (
          <Alert
            variant="danger"
            className="d-flex align-items-center"
            onClose={() => setRequestStatus({ status: 'idle' })}
            dismissible
          >
            {apiRequest.data}
          </Alert>
        )}

        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label htmlFor="nombre">Nombre</Form.Label>
            <Form.Control
              type="text"
              id="nombre"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.nombre}
            />
            <Form.Control.Feedback type="invalid" as="p">
              {formik.errors.nombre}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="row mb-4">
            <Form.Group className="col me-2">
              <Form.Label htmlFor="inicialNc">Inicial NC</Form.Label>
              <Form.Control
                type="text"
                id="inicialNc"
                name="inicialNc"
                value={formik.values.inicialNc}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.inicialNc}
                disabled={!!id}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.inicialNc}</Form.Control.Feedback>
            </Form.Group>

            <SelectTipoHerramienta formik={formik} className="col ms-2" />
          </div>

          <div className="row">
            <Form.Group className="col col-md-6 mb-5">
              <Form.Label htmlFor="periodoCertificacionId">Periodo de Certificación</Form.Label>
              <Form.Control
                as={Form.Select}
                id="periodoCertificacionId"
                name="periodoCertificacionId"
                value={formik.values.periodoCertificacionId}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.periodoCertificacionId}
              >
                <option value={0}>
                  {periodosCertificacion.status === 'pending' ? 'Cargando...' : 'Selecciona un periodo de certificación'}
                </option>
                {periodosCertificacion.data.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik.errors.periodoCertificacionId}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col col-md-6 mb-5">
              <Form.Label htmlFor="periodoMantenimeintoId">Periodo de Mantenimiento</Form.Label>
              <Form.Control
                as={Form.Select}
                id="periodoMantenimientoId"
                name="periodoMantenimientoId"
                value={formik.values.periodoMantenimientoId}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.periodoMantenimientoId}
              >
                <option value={0}>
                  {periodosMantenimiento.status === 'pending' ? 'Cargando...' : 'Selecciona un periodo de mantenimiento'}
                </option>
                {periodosMantenimiento.data.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik.errors.periodoMantenimientoId}</Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="text-end">
            <Button type="submit" variant="primary">
              {apiRequest.status === 'pending' ? <Spinner animation="border" /> : 'Guardar Herramienta'}
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
}
