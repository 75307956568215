import { ICatalogo } from '@app/models/catalogos/ICatalogo';
import { TipoHerramienta } from '@app/models/enums';
import { Catalogo } from '@app/models/enums/catalogos';
import { getCatalogo } from '@app/services';
import { FormikProps } from 'formik/dist/types';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

type SelectTipoHerramientaProps = {
  className?: string;
  formik: FormikProps<{ tipoHerramientaId: TipoHerramienta }>;
};

function SelectTipoHerramienta({ className, formik }: SelectTipoHerramientaProps) {
  const [tiposHerramienta, setTiposHerramienta] = useState<ICatalogo[]>([]);

  const obtenerTiposHerramienta = async () => {
    try {
      const response = await getCatalogo(Catalogo.TDeHerramienta);
      setTiposHerramienta(response);
    } catch (ex) {
      throw ex;
    }
  };

  useEffect(() => {
    obtenerTiposHerramienta();
    return () => {
      setTiposHerramienta([]);
    };
  }, []);
  return (
    <Form.Group className={className}>
      <Form.Label htmlFor="tipoHerramientaId">Tipo de Herramienta</Form.Label>
      <Form.Control
        as={Form.Select}
        id="tipoHerramientaId"
        name="tipoHerramientaId"
        value={formik.values.tipoHerramientaId}
        onChange={formik.handleChange}
        isInvalid={!!formik.errors.tipoHerramientaId}
      >
        <option value={0}>Selecciona un tipo de herramienta</option>
        {tiposHerramienta.map((her: ICatalogo) => (
          <option key={her.id} value={her.id}>
            {her.descripcion}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{formik.errors.tipoHerramientaId}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default SelectTipoHerramienta;
