import { Notificaciones } from '@app/models/enums';
import { IHerramientaNotificacion } from '@app/models/notificaciones/notificacion';
import { NotificacionProps } from '@app/models/types/notificacion.type';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteNotificacionHerramienta, getNotificacionesService } from '../services/notificaciones.service';
import EliminarNotificacionModal from './modals/EliminarNotificacionModal';

import notificacionStyle from '../styles/components/notificacion.module.scss';

/* eslint-disable react-hooks/exhaustive-deps */
export default function Notificacion({ datos }: NotificacionProps) {
  const navigate = useNavigate();
  const { concepto, tipoNotificacionId, tiposNotificaciones, fechaNotificacion, notificacionesHerramientas } = datos;
  const [estadoModalEliminarNotificacion, setEstadoModalEliminarNotificacion] = useState<{
    estado: boolean;
    notificacionHerramienta: IHerramientaNotificacion;
  }>({
    estado: false,
    notificacionHerramienta: {
      herramienta: '',
      complementoNombre: '',
      herramientaId: 0,
      notificacionId: 0,
    },
  });

  const datosNotificacion = async () => {
    try {
      await getNotificacionesService();
    } catch (err) {
      throw err;
    }
  };

  const notificacionEliminada = async (notificacionHerramienta: IHerramientaNotificacion) => {
    try {
      if (notificacionHerramienta.herramientaId && notificacionHerramienta.notificacionId) {
        await deleteNotificacionHerramienta(
          notificacionHerramienta.herramientaId,
          notificacionHerramienta.notificacionId
        );
      }
      setEstadoModalEliminarNotificacion(
        (noti: { estado: boolean; notificacionHerramienta: IHerramientaNotificacion }) => ({
          ...noti,
          estado: false,
        })
      );
      datosNotificacion();
      navigate(0);
    } catch (err) {
      throw err;
    }
  };

  const tituloNotificacion = useMemo(() => {
    return `${tiposNotificaciones.find(tipo => tipo.id === tipoNotificacionId)?.descripcion} - ${
      fechaNotificacion ? format(new Date(fechaNotificacion), 'dd/MM/yyyy') : '--/--/----'
    }`;
  }, [tipoNotificacionId, tiposNotificaciones]);

  useEffect(() => {
    datosNotificacion();
  }, []);

  return (
    <div
      className={classNames(notificacionStyle.notificacion, {
        [notificacionStyle.notificacion__certificacion]: tipoNotificacionId === Notificaciones.Certificacion,
        [notificacionStyle.notificacion__preventivo]: tipoNotificacionId === Notificaciones.Preventivo,
        [notificacionStyle.notificacion__correctivo]: tipoNotificacionId === Notificaciones.Correctivo,
        [notificacionStyle.notificacion__baja]: tipoNotificacionId === Notificaciones.Baja,
      })}
    >
      <h6 className="my-2">{tituloNotificacion}</h6>
      <h6 className="my-2">{concepto}</h6>
      {notificacionesHerramientas.length ? (
        <>
          <p className="my-1">Herramientas: </p>
          <ul className="row w-100 my-2">
            {notificacionesHerramientas.map((notificacion: IHerramientaNotificacion) => (
              <>
                <div className="col col-sm-11 col-md-11 my-1">
                  <li key={notificacion.herramientaId}>{notificacion.herramienta} {notificacion.complementoNombre}</li>
                </div>
                <div className="p-0 col col-sm-1 col-md-1 d-flex align-items-center justify-content-end">
                  <IconButton size="small">
                    <DeleteIcon
                      fontSize="medium"
                      color="inherit"
                      onClick={() =>
                        setEstadoModalEliminarNotificacion({ estado: true, notificacionHerramienta: notificacion })
                      }
                    />
                  </IconButton>
                </div>
              </>
            ))}
          </ul>
        </>
      ) : null}
      <EliminarNotificacionModal
        estadoModal={estadoModalEliminarNotificacion.estado}
        textoBody={`: ${estadoModalEliminarNotificacion.notificacionHerramienta?.herramienta} || ${estadoModalEliminarNotificacion.notificacionHerramienta?.complementoNombre}`}
        cambiarEstadoModal={setEstadoModalEliminarNotificacion}
        tituloModal={'Eliminar Notificacion Herramienta'}
        elemento={estadoModalEliminarNotificacion.notificacionHerramienta}
        notificacion={'Notificacion'}
        recuperarData={notificacionEliminada}
      />
    </div>
  );
}
