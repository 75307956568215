export enum Notificaciones {
  Certificacion = 1,
  Preventivo = 2,
  Correctivo = 3,
  Baja = 4,
}

export enum ColoresNotificaciones {
  Certificacion = '#FC3737',
  Preventivo = '#F7A800',
  Correctivo = '#F77327',
  Baja = '#373A3C',
}
