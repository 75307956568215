import axios from '@app/utils/request/axios';

type StatusTrabajador = {
  name: string;
  status: string;
  caducidad: 'EXPIRADA' | 'NO EXPIRADA';
  objectId: number;
  codigoemp: number;
};

export const estatusTrabajadorService = async (noTarjeta: number) => {
  try {
    const { data } = await axios.get<StatusTrabajador>(`UsuarioPeco/GetTarjetaInformacion/${noTarjeta}`);
    return { data, error: null };
  } catch (ex) {
    throw ex;
  }
};

export const trabajadorTarjetaService = async (codigo: number) => {
  try {
    const { data } = await axios.get(`UsuarioPeco/GetPecoUser/${codigo}`);
    return { data, error: null };
  } catch (ex) {
    throw ex;
  }
};
