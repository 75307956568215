import { IAsyncData } from '@app/models';
import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo } from '@app/models/enums';
import { getCatalogo } from '@app/services';
import { useEffect, useState } from 'react';

type ColeccionCatalogos = { [K in keyof typeof Catalogo]?: ICatalogoGenerico[] };

export function useCatalogos(tipos: Catalogo[]) {
  const [catalogos, setCatalogos] = useState<IAsyncData<ColeccionCatalogos>>({
    status: 'pending',
  });

  useEffect(() => {
    const promises = tipos.map(tipo => getCatalogo(tipo).then(data => ({ [tipo]: data } as ColeccionCatalogos)));

    Promise.all(promises).then(res => {
      const data = res.reduce((prev, next) => {
        return { ...prev, ...next };
      }, {} as ColeccionCatalogos);

      setCatalogos({ status: 'fulfilled', data });
    });
  }, []);

  return catalogos;
}
