import { ErrorMessages } from '@app/contants/errorMessages';
import * as Yup from 'yup';

export const nuevoMantenimientoValidaciones = Yup.object().shape({
  herramientaId: Yup.number().required(ErrorMessages.Required),
  proveedorId: Yup.number().min(1, ErrorMessages.Required),
  tipoDeMantenimientoId: Yup.number().min(1, ErrorMessages.Required),
  fechaMantenimiento: Yup.string().required(ErrorMessages.Required),
  comentarios: Yup.string(),
});
