import { ErrorMessages } from '@app/contants/errorMessages';
import * as Yup from 'yup';

export const nuevoCatalogoValidaciones = Yup.object().shape({
  descripcion: Yup.string().required(ErrorMessages.Required),
});

export const proveedorSchema = nuevoCatalogoValidaciones.shape({
  id: Yup.number().required(ErrorMessages.Required),
});
