import { ITipoNotificacion } from '@app/models/preferencias/IDestinatarioPreferencias';
import { DestinatarioPreferenciasProps } from '@app/models/types/destinatarioPreferencias.type';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { eliminarDestinatarioService } from '@app/services/preferencias.service';
import EliminarModal from '@app/components/modals/EliminarModal';
import { useState } from 'react';

export default function DestinatarioPreferencias(props: DestinatarioPreferenciasProps) {
  const { destinatario, destinatarioEliminado, modalEditarDestinatario } = props;
  const [estadoEliminarModal, setEstadoEliminarModal] = useState<boolean>(false);

  const estilos = {
    contenedor: {
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      width: '100%',
      padding: '10px',
      margin: '15px 0',
    },
    iconoEditar: {
      top: '15px',
      right: '15px',
      cursor: 'pointer',
    },
  };

  const eliminarDestinatario = async (idDestinatario: number) => {
    try {
      await eliminarDestinatarioService(idDestinatario);
      destinatarioEliminado(idDestinatario, null);
    } catch (ex) {
      destinatarioEliminado(0, ex);
    }
  };

  const respuestaModal = () => {
    setEstadoEliminarModal(false);
    eliminarDestinatario(destinatario.id);
  };

  return (
    <>
      <div style={{ ...estilos.contenedor, position: 'relative' }}>
        <div style={{ ...estilos.iconoEditar, position: 'absolute' }}>
          <EditIcon onClick={() => modalEditarDestinatario(destinatario)} />
          <DeleteIcon onClick={() => setEstadoEliminarModal(true)} />
        </div>
        <p>
          <span className="fw-bold">Nombre:</span>&nbsp;
          {destinatario.nombreNotificado}
        </p>
        <p>
          <span className="fw-bold">Puesto:</span>&nbsp;
          {destinatario.puesto}
        </p>
        <p>
          <span className="fw-bold">Correo Destinatario: </span>&nbsp;
          {destinatario.emailNotificado}
        </p>
        <p className="mb-2">
          <span className="fw-bold">Tipo Notificación:</span>&nbsp;
          {destinatario.notificaciones.length ? (
            <>
              {destinatario.notificaciones.map((notificacion: ITipoNotificacion) => (
                <span key={notificacion.id}>{notificacion.tipo},&nbsp;</span>
              ))}
            </>
          ) : (
            ''
          )}
        </p>
      </div>
      <EliminarModal
        estadoModal={estadoEliminarModal}
        cambiarEstadoModal={setEstadoEliminarModal}
        tituloModal="Eliminar Destinatario"
        nombreItemModal={destinatario.nombreNotificado}
        respuestaModal={respuestaModal}
      />
    </>
  );
}
