import React, { useState, useEffect  } from 'react';
import { Button, Form, Modal, Spinner, Image } from 'react-bootstrap';
import { IHerramienta} from '@app/models/herramienta/IHerramientaGeneral';
import { updateHerramientaEliminada } from '@app/services';

type EliminarHerramientaModalProps = {
    herramienta: Partial<IHerramienta>;
    onHide: () => void;
    show: boolean;
    recuperarIdHerramienta: (id: number) => void;
};

export default function EliminarHerramientaModal({
    herramienta,
    onHide,
    show,
    recuperarIdHerramienta,
}: EliminarHerramientaModalProps) {
    const [numeroControl, setNumeroControl] = useState<string>('');
    const [observaciones, setObservaciones] = useState<string>('');
    const [imagen, setImagen] = useState<File | null>(null);
    const [imagenPreview, setImagenPreview] = useState<string | null>(null);
    const [cargando, setCargando] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const isValid =
    numeroControl === herramienta.numeroControl &&
    observaciones.trim() !== '' &&
    imagen !== null;

    useEffect(() => {
        if (!show) {
            setImagenPreview(null);
        }
    }, [show]);

    const handleImagenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            // Validación para asegurarse de que el archivo es una imagen
            if (!file.type.startsWith('image/')) {
                setError('El archivo seleccionado no es una imagen válida.');
                setImagen(null); // Limpiar la imagen si no es válida
                setImagenPreview(null);
            } else {
                setImagen(file); // Establecer la imagen si es válida
                setError(''); // Limpiar el error si se selecciona un archivo válido

                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagenPreview(reader.result as string);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const eliminarHerramienta = async () => {
        // 1. Validar si la herramienta está prestada
        if (herramienta.responsable) {
            setError('No se puede eliminar una herramienta que está prestada.');
            return;
        }

        // 2. Validar si los campos son correctos
        if (!isValid) {
            setError('Por favor, complete todos los campos correctamente.');
            return;
        }

        setCargando(true);
        setError('');

        try {
            const formData = new FormData();
            formData.append('observacionesEliminado', observaciones);
            
            if (imagen) {
                formData.append('imagenEliminado', imagen);
            }

            await updateHerramientaEliminada(herramienta.id!, formData);
            recuperarIdHerramienta(herramienta.id!);
            handleClose();
        } catch (ex) {
            console.error(ex);
            setError('Ocurrió un error al eliminar la herramienta. Por favor, inténtelo de nuevo.');
        } finally {
            setCargando(false);
        }
    };

    // Función que limpia los campos y cierra el modal
    const handleClose = () => {
        // Limpiar los valores al cerrar o después de eliminar
        setNumeroControl('');
        setObservaciones('');
        setImagen(null);
        setImagenPreview(null);
        setError('');
        onHide();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar herramienta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    ¿Estás seguro de que quieres eliminar la herramienta <strong>{herramienta.numeroControl}</strong>?
                </p>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="numeroControl">Confirma escribiendo el número de control:</Form.Label>
                        <Form.Control
                            id="numeroControl"
                            name="numeroControl"
                            placeholder={herramienta.numeroControl}
                            value={numeroControl}
                            onChange={e => {
                                setNumeroControl(e.target.value);
                                if (error) setError('');
                            }}
                            isInvalid={!!numeroControl && numeroControl !== herramienta.numeroControl}
                        />
                        <Form.Control.Feedback type="invalid">
                            El número de control ingresado no es el correcto.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="observaciones">Observaciones:</Form.Label>
                        <Form.Control
                            id="observaciones"
                            name="observaciones"
                            as="textarea"
                            rows={3}
                            value={observaciones}
                            onChange={e => {
                                setObservaciones(e.target.value);
                                if (error) setError('');
                            }}
                            isInvalid={!observaciones.trim()}
                        />
                        <Form.Control.Feedback type="invalid">
                            Este campo es obligatorio.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="imagen">Imagen:</Form.Label>
                        <Form.Control
                            id="imagen"
                            name="imagen"
                            type="file"
                            accept="image/*"
                            onChange={handleImagenChange}
                            isInvalid={!imagen}
                        />
                        <Form.Control.Feedback type="invalid">
                            Debe seleccionar una imagen.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {imagenPreview && (
                        <div className="mt-3">
                            <p>Vista previa de la imagen:</p>
                            <Image src={imagenPreview} alt="Vista previa" thumbnail style={{ maxWidth: '200px' }} />
                        </div>
                    )}

                    {error && <div className="alert alert-danger">{error}</div>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose} disabled={cargando}>
                    Cancelar
                </Button>
                <Button onClick={eliminarHerramienta} variant="danger" disabled={cargando || !isValid}>
                    {cargando ? <Spinner animation="border" size="sm" /> : 'Eliminar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
