import { useCallback, useState, useEffect, useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ImagePicker from '@app/components/ImagePicker';
import TitleComponent from '@app/components/Title';
import Loading from '@app/components/Loading';
import FormHerramientaGeneral from '@app/components/forms/FormHerramientaGeneral';
import FormHerramientaHidraulica from '@app/components/forms/FormHerramientaHidraulica';
import FormHerramientaMecanica from '@app/components/forms/FormHerramientaMecanica';
import FormHerramientaMenor from '@app/components/forms/FormHerramientaMenor';
import { AppRoutes } from '@app/contants/app-routes';
import { IAsyncData } from '@app/models';
import { IFotoHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { IHerramientaGenerica } from '@app/models/herramienta/IHerramientaGenerica';
import { TipoHerramienta } from '@app/models/enums';
import { IFotosHerramienta } from '@app/models/IFotosHerramienta';
import { agregarHerramientaService, getHerramientaGenericaById } from '@app/services/herramienta.service';
import {
    herramientaHidraulicaSchema,
    herramientaMecanicaSchema,
    herramientaMenorSchema,
    herramientaSchema,
} from '@app/utils/validaciones/herramienta.schema';

export default function AgregarNuevaHerramienta() {
    const navigate = useNavigate();
    const { idHerramienta } = useParams<{ idHerramienta: string }>();
    const [herramienta, setHerramienta] = useState<IAsyncData<IHerramientaGenerica>>({ status: 'pending' });
    const [cargando, setCargando] = useState(false);
    const [nuevasFotos, setNuevasFotos] = useState<IFotosHerramienta>({
        imagenUno: null,
        imagenDos: null,
    });

    const validationSchema = useMemo(() => {
        switch (herramienta.data?.tipoHerramientaId) {
            case TipoHerramienta.Mecanica:
                return herramientaMecanicaSchema;
            case TipoHerramienta.Menores:
                return herramientaMenorSchema;
            case TipoHerramienta.Hidraulica:
                return herramientaHidraulicaSchema;
            default:
                return herramientaSchema;
        }
    }, [herramienta.data?.tipoHerramientaId]);

    const enviarNuevaHerramienta = async values => {
        try {
            const formData = new FormData();
            formData.append('herramientaGenericaId', idHerramienta);
            for (let key in values) {
                formData.append(key, values[key]);
            }
            setCargando(true);
            if (nuevasFotos.imagenUno) {
                formData.append('fotos', nuevasFotos.imagenUno.data);
            }
            if (nuevasFotos.imagenDos) {
                formData.append('fotos', nuevasFotos.imagenDos.data);
            }

            await agregarHerramientaService(formData);
            setCargando(false);
            navigate(AppRoutes.CatalogoAlmacen);
        } catch (ex) {
            setCargando(false);
        }
    };

    const formik = useFormik({
        initialValues: {},
        validationSchema,
        onSubmit: enviarNuevaHerramienta,
    });
    const { handleSubmit } = formik;

    const renderForm = () => {
        if (herramienta.status === 'fulfilled') {
            switch (herramienta.data?.tipoHerramientaId) {
                case TipoHerramienta.Mecanica:
                    return <FormHerramientaMecanica herramientaGenericaId={idHerramienta} onSubmit={enviarNuevaHerramienta} />;
                case TipoHerramienta.Menores:
                    return <FormHerramientaMenor herramientaGenericaId={idHerramienta} onSubmit={enviarNuevaHerramienta} />;
                case TipoHerramienta.Hidraulica:
                    return <FormHerramientaHidraulica herramientaGenericaId={idHerramienta} onSubmit={enviarNuevaHerramienta} />;
                default:
                    return <FormHerramientaGeneral herramientaGenericaId={idHerramienta} onSubmit={enviarNuevaHerramienta} />;
            }
        } else if (herramienta.status === 'rejected') {
            return (
                <Alert variant="danger" dismissible>
                    Ocurrió un error al intentar obtener los detalles del tipo de herramienta. Por favor intente de nuevo.
                </Alert>
            );
        } else {
            return <Loading mensaje="Cargando" />;
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await getHerramientaGenericaById(idHerramienta);
                setHerramienta({ status: 'fulfilled', data });
            } catch (ex) {
                setHerramienta({ status: 'rejected' });
            }
        })();
    }, [idHerramienta]);

    const handleImageChange = useCallback(
        (newImages: { imagenUno: IFotoHerramienta | null; imagenDos: IFotoHerramienta | null }) => {
            setNuevasFotos(newImages);
        },
        [setNuevasFotos]
    );

    return (
        <Container className="d-flex flex-column mt-5" fluid>
            <TitleComponent titulo="Agregar Nueva Herramienta" ruta={AppRoutes.CatalogoAlmacen} />
            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit} noValidate>
                    <Row className="w-100 mt-2 mb-3 border-bottom border-dark border-2 pb-2">
                        <Col xs={4} sm={4} md={4} className="d-flex align-items-center justify-content-around">
                            <p>
                                Tipo Herramienta:&nbsp;&nbsp;<span className="fw-bold">{herramienta.data?.tipoHerramienta}</span>
                            </p>
                        </Col>
                        <Col xs={4} sm={4} md={4} className="d-flex align-items-center justify-content-around">
                            <p>
                                Nombre Inicial:&nbsp;&nbsp;<span className="fw-bold">{herramienta.data?.nombre}</span>
                            </p>
                        </Col>
                        <Col xs={4} sm={4} md={4} className="d-flex align-items-start justify-content-center">
                            <Button type="submit" variant="primary" disabled={cargando}>
                                {cargando ? <Spinner animation="border" /> : 'Guardar Herramienta'}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={9} sm={9} md={9}>
                            {renderForm()}
                        </Col>
                        <Col xs={3} sm={3} md={3}>
                            <ImagePicker onChange={handleImageChange} images={[nuevasFotos.imagenUno, nuevasFotos.imagenDos]} />
                        </Col>
                    </Row>
                </Form>
            </FormikProvider>
        </Container>
    );
}
