import { Modal, Image } from 'react-bootstrap';

const ImagenEliminadoModal = ({ show, onHide, imagenBase64, title }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton className="border-bottom-0">
        <Modal.Title className="w-100 text-center">{title || 'Imagen Eliminado'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Image
          src={`data:image/jpeg;base64,${imagenBase64}`}
          alt="Imagen Eliminado"
          fluid
          style={{
            maxHeight: '85vh',
            width: 'auto',
            maxWidth: '100%',
            objectFit: 'contain'
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImagenEliminadoModal;