import DestinatarioPreferencias from '@app/components/DestinatarioPreferencias';
import Loading from '@app/components/Loading';
import DestinatarioPreferenciasModal from '@app/components/modals/DestinatarioPreferenciasModal';
import Title from '@app/components/Title';
import useError from '@app/hooks/useError';
import { IDestinatarioPreferencias } from '@app/models/preferencias/IDestinatarioPreferencias';
import { obtenerDestinatariosService } from '@app/services/preferencias.service';
import styles from '@app/styles/pages/Preferencias.module.scss';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

export default function Preferencias() {
  const [cargando, setCargando] = useState<boolean>(false);
  const [modalDestinatario, setModalDestinatario] = useState<boolean>(false);
  const [detalleDestinatario, setDetalleDestinatario] = useState<IDestinatarioPreferencias | null>(null);
  const [destinatarios, setDestinatarios] = useState<IDestinatarioPreferencias[]>([]);
  const { ErrorAlert, setError } = useError();

  const abrirModalDestinatario = (dataDestinatario: IDestinatarioPreferencias | null) => {
    setDetalleDestinatario(dataDestinatario);
    setModalDestinatario(true);
  };

  const obtenerDestinatarios = async () => {
    try {
      setCargando(true);
      const destinatariosResult = await obtenerDestinatariosService();
      setDestinatarios(destinatariosResult);
    } catch (ex) {
      setError(ex?.response?.data);
    } finally {
      setCargando(false);
    }
  };

  const recuperarDestinatario = (destinatarioData: IDestinatarioPreferencias, editado: boolean) => {
    if (!!!editado) {
      setDestinatarios((dest: IDestinatarioPreferencias[]) => [...dest, destinatarioData]);
    } else {
      setDestinatarios((dest: IDestinatarioPreferencias[]) =>
        dest.map((destinatario: IDestinatarioPreferencias) => {
          if (destinatario.id === destinatarioData.id) {
            return destinatarioData;
          }
          return destinatario;
        })
      );
    }
  };

  const destinatarioEliminado = (id: number, error: any) => {
    if (!!!error) {
      const destinatariosActualizados = destinatarios.filter(
        (destinatario: IDestinatarioPreferencias) => destinatario.id !== id
      );
      setDestinatarios(destinatariosActualizados);
    } else {
      setError(error?.response?.data);
    }
  };

  useEffect(() => {
    obtenerDestinatarios();
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      {cargando && <Loading mensaje="Cargando preferencias" />}
      <ErrorAlert />
      <Title titulo="Preferencias Notificaciones" ruta="" />
      <Row>
        <Col className="d-flex align-items-end justify-content-between mx-auto mt-4" xs={12} sm={12} md={10} lg={10}>
          <h5 className="my-3 mx-0">Destinatarios Preferencias</h5>
          <Button variant="secondary" onClick={() => abrirModalDestinatario(null)}>
            Agregar Destinatario
          </Button>
        </Col>
      </Row>
      <Row className={styles.destinatario}>
        <Col className="mx-auto mt-4" xs={12} sm={12} md={10} lg={10}>
          {destinatarios.length ? (
            <>
              {destinatarios.map((destinatario: IDestinatarioPreferencias) => (
                <DestinatarioPreferencias
                  key={destinatario.id}
                  destinatario={destinatario}
                  destinatarioEliminado={destinatarioEliminado}
                  modalEditarDestinatario={abrirModalDestinatario}
                />
              ))}
            </>
          ) : (
            <h5>No hay destinatarios registrados</h5>
          )}
        </Col>
      </Row>
      <DestinatarioPreferenciasModal
        estadoModal={modalDestinatario}
        cambiarEstadoModal={setModalDestinatario}
        recuperarDestinatario={recuperarDestinatario}
        detalleDestinatario={detalleDestinatario}
      />
    </Container>
  );
}
