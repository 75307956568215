import { TipoMovimiento } from '@app/models/enums';
import { IMovimiento, IMovimientoRequest, IPrestamo, IResguardo } from '@app/models/prestamos/IPrestamo';
import axios from '@app/utils/request/axios';

export async function postAddSalida(request: IMovimientoRequest) {
  try {
    const { data } = await axios.post<IMovimiento>('Movimientos', request);

    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function postAddEntrada(request: IMovimientoRequest) {
  try {
    const { data } = await axios.post('Movimientos', request);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getMovimientoById(id: number | string) {
  try {
    const { data } = await axios.get<IMovimiento>(`Movimientos/${id}`);

    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getSalidasRecientes() {
  try {
    const { data } = await axios.get<IPrestamo[]>('Movimientos/recientes', { params: { tipo: TipoMovimiento.Salida } });

    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getPrestamosPendientes(pagina: number = 1, cantidadPaginas = 25) {
  try {
    const { data } = await axios.get<IPrestamo[]>('Movimientos/pendientes', {
      params: {
        page: pagina,
        pageSize: cantidadPaginas,
      },
    });

    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getMovimientos(pagina: number = 1, cantidadPaginas = 25, estado?: number, tipo?: number) {
  try {
    let params: { page: number; pageSize: number; estado?: number; tipo?: number } = {
      page: pagina,
      pageSize: cantidadPaginas,
    };
    if (!!estado) {
      params = { ...params, estado };
    }
    if (!!tipo) {
      params = { ...params, tipo };
    }
    const { data } = await axios.get<IPrestamo[]>('Movimientos', {
      params,
    });
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getMovimientosFilter(
  filter: string = '',
  pagina: number = 1,
  cantidadPaginas = 25,
  estado?: number,
  tipo?: number
) {
  try {
    let params: { filter: string; page: number; pageSize: number; estado?: number; tipo?: number } = {
      filter,
      page: pagina,
      pageSize: cantidadPaginas,
    };
    if (!!estado) {
      params = { ...params, estado };
    }
    if (!!tipo) {
      params = { ...params, tipo };
    }
    const { data } = await axios.get<IPrestamo[]>('Movimientos/GetMovimimientosHistoricoFilter', {
      params,
    });
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getPrestamosPendientesTrabajador(codigoEmp: number) {
  try {
    const { data } = await axios.get<IPrestamo[]>('Movimientos/pendientes/trabajador', {
      params: {
        codigoEmp,
      },
    });

    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function putPrestamosResguardo(idMovimiento: number, herramientas: IResguardo[]) {
  try {
    const { data } = await axios.put(`Movimientos/resguardo/${idMovimiento}`, {
      id: idMovimiento,
      resguardos: herramientas,
    });
    return data;
  } catch (ex) {
    throw ex;
  }
}
