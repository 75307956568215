import { useCatalogos } from '@app/hooks';
import { Catalogo } from '@app/models/enums';
import { IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { getNumeroDeControl } from '@app/services';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';

export type FormHerramientaMecanicaProps = {
  herramienta?: IHerramienta;
  herramientaGenericaId?: string | number;
  submitting?: boolean;
  onSubmit?: (values: IHerramienta) => void;
};

/* eslint-disable react-hooks/exhaustive-deps */
export default function FormHerramientaMecanica({ herramientaGenericaId }: FormHerramientaMecanicaProps) {
  const catalogos = useCatalogos([Catalogo.Ubicaciones, Catalogo.Estados, Catalogo.UnidadesDeMedida]);
  const { values, setFieldValue, errors, handleChange } = useFormikContext<IHerramienta>() || {};

  useEffect(() => {
    if (herramientaGenericaId) {
      getNumeroDeControl(herramientaGenericaId).then(res => {
        setFieldValue('numeroControl', res, false);
      });
    }
  }, [herramientaGenericaId]);

  return (
    <>
      <Row>
        {herramientaGenericaId && (
          <Form.Group className="col col-3 mb-3">
            <Form.Label htmlFor="numeroControl">Número de Control</Form.Label>
            <Form.Control
              type="text"
              id="numeroControl"
              name="numeroControl"
              onChange={handleChange}
              value={values?.numeroControl}
              isInvalid={!!errors?.numeroControl}
              disabled
            />
            <Form.Control.Feedback type="invalid" as="p">
              {errors?.numeroControl}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group className="col col-3 mb-3">
                    <Form.Label htmlFor="complementoNombre">Complemento Nombre</Form.Label>
                    <Form.Control
                        type="text"
                        id="complementoNombre"
                        name="complementoNombre"
                        onChange={handleChange}
                        value={values.complementoNombre || ""}  // Asegurar que no sea undefined
                        isInvalid={!!errors?.complementoNombre}
                    />
                    <Form.Control.Feedback type="invalid" as="p">
                        {errors?.complementoNombre}
                    </Form.Control.Feedback>
                </Form.Group>
        <Form.Group className="col col-3 mb-3">
          <Form.Label htmlFor="fechaCompra">Fecha de Compra</Form.Label>
          <Form.Control
            type="date"
            id="fechaCompra"
            name="fechaCompra"
            onChange={handleChange}
            value={values?.fechaCompra}
            isInvalid={!!errors?.fechaCompra}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.fechaCompra}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-3 mb-3">
          <Form.Label htmlFor="modelo">Modelo</Form.Label>
          <Form.Control
            type="text"
            id="modelo"
            name="modelo"
            onChange={handleChange}
            value={values?.modelo}
            isInvalid={!!errors?.modelo}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.modelo}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="numeroSerie">Número de Serie</Form.Label>
          <Form.Control
            type="text"
            id="numeroSerie"
            name="numeroSerie"
            onChange={handleChange}
            value={values?.numeroSerie}
            isInvalid={!!errors?.numeroSerie}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.numeroSerie}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="marca">Marca</Form.Label>
          <Form.Control
            type="text"
            id="marca"
            name="marca"
            onChange={handleChange}
            value={values?.marca}
            isInvalid={!!errors?.marca}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.marca}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="costo">Costo</Form.Label>
          <Form.Control
            type="number"
            id="costo"
            name="costo"
            onChange={handleChange}
            value={values?.costo}
            isInvalid={!!errors?.costo}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.costo}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="ubicacionId">Ubicación</Form.Label>
          <Form.Control
            as={Form.Select}
            id="ubicacionId"
            name="ubicacionId"
            onChange={handleChange}
            value={values?.ubicacionId}
            isInvalid={!!errors?.ubicacionId}
          >
            <option value={null}>Seleccione una ubicación</option>
            {catalogos.data?.Ubicaciones.map(item => (
              <option key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.ubicacionId}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="estadoId">Estado</Form.Label>
          <Form.Control
            as={Form.Select}
            id="estadoId"
            name="estadoId"
            onChange={handleChange}
            value={values?.estadoId}
            isInvalid={!!errors?.estadoId}
          >
            <option value={null}>Seleccione un estado</option>
            {catalogos.data?.Estados.map(item => (
              <option key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.estadoId}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="presion">Presión (PSI)</Form.Label>
          <Form.Control
            type="text"
            id="presion"
            name="presion"
            onChange={handleChange}
            value={values?.presion}
            isInvalid={!!errors?.presion}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.presion}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="torque">Torque</Form.Label>
          <Form.Control
            type="text"
            id="torque"
            name="torque"
            onChange={handleChange}
            value={values?.torque}
            isInvalid={!!errors?.torque}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.torque}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="unidadMedidaId">Unidad de Medida</Form.Label>
          <Form.Control
            as={Form.Select}
            id="unidadMedidaId"
            name="unidadMedidaId"
            onChange={handleChange}
            value={values?.unidadMedidaId}
            isInvalid={!!errors?.unidadMedidaId}
          >
            <option value={null}>Seleccione una unidad de medida</option>
            {catalogos.data?.UnidadesDeMedida.map(item => (
              <option key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.unidadMedidaId}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="velocidadMotor">Velocidad del Motor</Form.Label>
          <Form.Control
            type="text"
            id="velocidadMotor"
            name="velocidadMotor"
            onChange={handleChange}
            value={values?.velocidadMotor}
            isInvalid={!!errors?.velocidadMotor}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.velocidadMotor}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="rangoCapacidadMinima">Rango Capacidad Mínima</Form.Label>
          <Form.Control
            type="text"
            id="rangoCapacidadMinima"
            name="rangoCapacidadMinima"
            onChange={handleChange}
            value={values?.rangoCapacidadMinima}
            isInvalid={!!errors?.rangoCapacidadMinima}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.rangoCapacidadMinima}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="rangoCapacidadMaxima">Rango Capacidad Máxima</Form.Label>
          <Form.Control
            type="text"
            id="rangoCapacidadMaxima"
            name="rangoCapacidadMaxima"
            onChange={handleChange}
            value={values?.rangoCapacidadMaxima}
            isInvalid={!!errors?.rangoCapacidadMaxima}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.rangoCapacidadMaxima}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="col col-4 mb-4">
          <Form.Label htmlFor="peso">Peso</Form.Label>
          <Form.Control
            type="text"
            id="peso"
            name="peso"
            onChange={handleChange}
            value={values?.peso}
            isInvalid={!!errors?.peso}
          />
          <Form.Control.Feedback type="invalid" as="p">
            {errors?.peso}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  );
}
