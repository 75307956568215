import { ICatalogo } from '@app/models/catalogos/ICatalogo';
import { Catalogo, PeriodoCertificacion, TipoHerramienta } from '@app/models/enums';
import { IItemCatalogo } from '@app/models/herramienta/IHerramientaGenerica';
import axios from '@app/utils/request/axios';

export async function getTiposDeHerramienta() {
  try {
    const { data } = await axios.get<IItemCatalogo<TipoHerramienta>[]>('Catalogos/TiposDeHerramienta');

    return data;
  } catch (e) {}
}

export async function getPeriodosDeCertificacion() {
  try {
    const { data } = await axios.get<IItemCatalogo<PeriodoCertificacion>[]>('Catalogos/PeriodosDeCertificacion');

    return data;
  } catch (e) {}
}

export async function addCatalogo(nombreCatalogo: Catalogo, datosCatalogo: ICatalogo) {
  try {
    const { data } = await axios.post(`Catalogos/${nombreCatalogo}`, datosCatalogo);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function getCatalogo(nombreCatalogo: Catalogo) {
  try {
    const { data } = await axios.get<ICatalogo[]>(`Catalogos/${nombreCatalogo}`);

    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function editCatalogo(nombreCatalogo: Catalogo, datosCatalogo: ICatalogo) {
  try {
    const { data } = await axios.put(`Catalogos/${nombreCatalogo}`, datosCatalogo);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function deleteCatalogo(nombreCatalogo: Catalogo, idCatalogo: number) {
  try {
    const { data } = await axios.delete(`Catalogos/${nombreCatalogo}/${idCatalogo}`);
    return data;
  } catch (ex) {
    throw ex;
  }
}
