import { ErrorMessages } from '@app/contants/errorMessages';
import * as Yup from 'yup';

export const nuevoDestinatarioValidaciones = Yup.object().shape({
  nombreNotificado: Yup.string().required(ErrorMessages.Required),
  puesto: Yup.string(),
  emailNotificado: Yup.string().email(ErrorMessages.Email).required(ErrorMessages.Required),
});

export const nuevoRemitenteValidaciones = Yup.object().shape({
  nombreRemitente: Yup.string().required(ErrorMessages.Required),
  emailRemitente: Yup.string().email(ErrorMessages.Email).required(ErrorMessages.Required),
  contrasenaRemitente: Yup.string().required(ErrorMessages.Required),
  hostRemitente: Yup.string().required(ErrorMessages.Required),
  puertoRemitente: Yup.number().required(ErrorMessages.Required),
  hostSMTPRemitente: Yup.string().required(ErrorMessages.Required),
  puertoSMTPRemitente: Yup.number().required(ErrorMessages.Required),
});
