import EliminarHerramientGenericaModal from '@app/components/modals/EliminarHerramientGenericaModal';
import { AppRoutes } from '@app/contants/app-routes';
import { IHerramientaGenerica } from '@app/models/herramienta/IHerramientaGenerica';
import { catalogoListaService } from '@app/services/herramienta-generica.service';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export default function CatalogoAlmacen() {
  const navigate = useNavigate();
  const [herramientasAlmacen, setHerramientasAlmacen] = useState<IHerramientaGenerica[]>([]);
  const [herramientasAlmacenAuxiliar, setHerramientasAlmacenAuxiliar] = useState<IHerramientaGenerica[]>([]);
  const [showModalEliminar, setShowModalEliminar] = useState<boolean>(false);
  const [herramientaPorEliminar, setHerramientaPorEliminar] = useState<Partial<IHerramientaGenerica>>({});

  const agregarHerramienta = (id: number) => navigate(`/agregar-herramienta/${id}`);

  const buscarHerramienta = paramHerramienta => {
    const herramientasEncontradas = herramientasAlmacenAuxiliar.filter(her =>
      !paramHerramienta ? her : her.nombre.toLowerCase().includes(paramHerramienta.toLowerCase())
    );
    setHerramientasAlmacen(herramientasEncontradas);
  };

  const mostrarModalEliminar = (herramienta: IHerramientaGenerica) => {
    setHerramientaPorEliminar(herramienta);
    setShowModalEliminar(true);
  };

  const cerrarModalEliminar = () => {
    setHerramientaPorEliminar({});
    setShowModalEliminar(false);
  };

  const idHerramientaRecuperada = (id: number) => {
    const nuevasHerramientas = herramientasAlmacenAuxiliar.filter((he: IHerramientaGenerica) => he.id !== id);
    setHerramientasAlmacen(nuevasHerramientas);
    setHerramientasAlmacenAuxiliar(nuevasHerramientas);
  };

  useEffect(() => {
    (async () => {
      const { data } = await catalogoListaService();
      setHerramientasAlmacen(data);
      setHerramientasAlmacenAuxiliar(data);
    })();
  }, []);

  return (
    <>
      <Container className="d-flex flex-column mt-5" fluid>
        <h1 className="text-center mb-5">Catálogo de Almacén</h1>
        <Row>
          <Col md={5}>
            <Form.Group>
              <Form.Control
                type="search"
                onChange={({ target }) => buscarHerramienta(target.value)}
                placeholder="Buscar Herramienta"
              />
            </Form.Group>
          </Col>
          <Col md={7} className="d-flex justify-content-end">
            <Link className="btn btn-primary" role="button" to={AppRoutes.NuevaHerramientaGenerica}>
              Agregar Nuevo Catálogo
            </Link>
          </Col>
        </Row>
        <Row className="mt-5">
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>No.</th>
                <th>NC Inicial</th>
                <th>Nombre</th>
                <th>Tipo Herramienta</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!herramientasAlmacen.length ? (
                <tr>
                  <td colSpan={5}>No hay herramientas registradas.</td>
                </tr>
              ) : (
                <>
                  {herramientasAlmacen.map(herramienta => (
                    <tr
                      key={herramienta.id}
                      data-testid="fila-tbody"
                      style={{ cursor: 'pointer' }}
                      onClick={() => agregarHerramienta(herramienta.id)}
                    >
                      <td>{herramienta.id}</td>
                      <td>{herramienta.inicialNC}</td>
                      <td>{herramienta.nombre}</td>
                      <td>{herramienta.tipoHerramienta}</td>
                      <td>
                        <EditIcon onClick={(event) => {
                          event.stopPropagation();
                          navigate(`/editar-herramienta-generica/${herramienta.id}`);
                        }}/>&nbsp;&nbsp;
                        <DeleteIcon onClick={(event) => {
                          event.stopPropagation();
                          mostrarModalEliminar(herramienta)
                        }}/>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
        </Row>
      </Container>
      <EliminarHerramientGenericaModal         
        show={showModalEliminar}
        herramienta={herramientaPorEliminar}
        onHide={cerrarModalEliminar}
        recuperarIdHerramienta={idHerramientaRecuperada}
      />
    </>
  );
}
