import { useEffect, useRef, useState } from 'react';
import Loading from '@app/components/Loading';
import CertificacionesModal from '@app/components/modals/CertificacionesModal';
import DetalleHerramientaModal from '@app/components/modals/DetalleHerramientaModal';
import EliminarHerramientaModal from '@app/components/modals/EliminarHerramientaModal';
import ListasHerramientaModal from '@app/components/modals/ListasHerramientaModal';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { ICatalogo } from '@app/models/catalogos/ICatalogo';
import { Catalogo, FiltroHerramienta, ROLES } from '@app/models/enums';
import { IDisponibilidadHerramienta } from '@app/models/herramienta/IDisponibilidad';
import { IHerramienta, ISummaryHerramientas } from '@app/models/herramienta/IHerramientaGeneral';
import { IItemMantenimiento } from '@app/models/herramienta/IMantenimiento';
import { getCatalogo, getHerramientas } from '@app/services';
import { getReporteHerramientasService } from '@app/services/reportes.service';
import styles from '@app/styles/pages/TablaHerramienta.module.scss';
import { descargarArchivo } from '@app/utils/descargarArchivo';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

// Importar los nuevos tipos de estado
import { ModalCertificacionesState } from '@app/models/modal-certificaciones';
import { ShowModalDetalleState } from '@app/models/show-modal-detalle';
import { CatalogosState } from '@app/models/catalogos-state';
import { DataModalState } from '@app/models/data-modal';

export default function TablaHerramienta() {
    const roleUsuario = JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase();
    const [cargando, setCargando] = useState<boolean>(true);
    const [filtroHerramienta, setFiltroHerramienta] = useState(FiltroHerramienta.Todas);

    // Uso de los nuevos tipos de estado
    const [modalCertificacionesData, setModalCertificacionesData] = useState<ModalCertificacionesState>({ show: false, data: {} });
    const [showModalDetalle, setShowModalDetalle] = useState<ShowModalDetalleState>({ estado: false, data: null });
    const [catalogos, setCatalogos] = useState<CatalogosState>({ unidades: [], capacidadCilindro: [] });
    const [dataModal, setDataModal] = useState<DataModalState>({
        idHerramienta: 0,
        nombreHerramienta: '',
        titulo: '',
        data: [],
    });

    const [showModalEliminar, setShowModalEliminar] = useState<boolean>(false);
    const [disponibilidadHerramienta, setDisponibilidadHerramienta] = useState<IDisponibilidadHerramienta>({ disponibles: 0, prestadas: 0 });
    const [herramientasSeleccionadas, setHerramientasSeleccionadas] = useState<IHerramienta[]>([]);
    const [herramientaPorEliminar, setHerramientaPorEliminar] = useState<Partial<IHerramienta>>({});
    const [modalMantenimiento, setModalMantenimiento] = useState<boolean>(false);
    const [paginacion, setPaginacion] = useState<{ numeroPagina: number; paginas: number; masRegistros: boolean }>({
        numeroPagina: 1,
        paginas: 25,
        masRegistros: false,
    });
    const [herramientasGenerales, setHerramientasGenerales] = useState<ISummaryHerramientas>({
        disponible: 0,
        prestada: 0,
        elementos: [],
    });
    const [descargandoReporte, setDescargandoReporte] = useState<boolean>(false);
    const [queryBusqueda, setQueryBusqueda] = useState<string>('');
    const pdfsRef = useRef(null);
    const imprimirQRs = useReactToPrint({
        content: () => pdfsRef.current,
        documentTitle: 'herramientas',
    });
    const { setError, ErrorAlert } = useError();

    const abrirModalMantenimientoCertificaciones = (id: number, titulo: 'Mantenimientos' | 'Certificaciones') => {
        const herramienta = herramientasGenerales.elementos.find(item => item.id === id);
        setDataModal({
            idHerramienta: herramienta.id,
            nombreHerramienta: herramienta.modelo,
            titulo,
            data: titulo === 'Mantenimientos' ? herramienta.mantenimientos : herramienta.certificaciones,
        });
        setModalMantenimiento(true);
    };

    const obtenerHerramientas = (
        numPagina: number = paginacion.numeroPagina,
        cantidadElementos: number = 25,
        queryBusqueda: string = '',
        filtro: number = filtroHerramienta
    ) => {
        getHerramientas(numPagina, cantidadElementos, queryBusqueda, filtro)
            .then(res => {
                setHerramientasGenerales(data => ({
                    ...data,
                    elementos: numPagina === 1 ? res.elementos : [...data.elementos, ...res.elementos],
                }));
                setDisponibilidadHerramienta({
                    prestadas: res.prestada,
                    disponibles: res.disponible,
                });
                setPaginacion(paginacion => ({
                    ...paginacion,
                    numeroPagina: numPagina === 1 ? 2 : paginacion.numeroPagina + 1,
                    masRegistros: !!res.elementos.length,
                }));
                setTimeout(() => {
                    setCargando(false);
                }, 1000);
            })
            .catch(ex => {
                setCargando(false);
                setError(ex);
            });
    };

    const obtenerUnidadesMedida = async () => {
        try {
            const unidades = await getCatalogo(Catalogo.UnidadesDeMedida);
            const capacidadCilindro = await getCatalogo(Catalogo.CompatibilidadesCilindro);
            setCatalogos({
                unidades,
                capacidadCilindro,
            });
        } catch (ex) {
            setError(ex?.response?.data);
        }
    };

    const descargarTodosQR = () => {
        const plantillaQR = (
            <div
                className={classNames(styles.pageBreak, 'd-flex align-items-center justify-content-between flex-wrap')}
                ref={pdfsRef}
            >
                {herramientasSeleccionadas.length &&
                    herramientasSeleccionadas
                        .sort((first, second) => +first.id - +second.id)
                        .map((item: IHerramienta) => (
                            <div
                                key={item.id}
                                className="my-2 py-2 px-3 pt-3 d-flex align-items-center justify-content-center flex-column"
                                style={{ border: 'solid 2px #000000' }}
                            >
                                <QRCode value={`${item.numeroControl}`} size={150} />
                                <p className="fw-bold m-0 mt-2 text-center" style={{ fontSize: '14px' }}>
                                    NC - {item.numeroControl}
                                </p>
                            </div>
                        ))}
            </div>
        );
        ReactDOM.render(plantillaQR, document.querySelector('#qrs-container'));
    };

    // Función para actualizar el estado al eliminar una herramienta
    const idHerramientaRecuperada = (id: number) => {
        const nuevasHerramientas = herramientasGenerales.elementos.filter((he: IHerramienta) => he.id !== id);
        setHerramientasGenerales((result: ISummaryHerramientas) => ({
            ...result,
            elementos: nuevasHerramientas,
        }));
    };

    const mantenimientoCertificacionRecuperado = (respuesta: IItemMantenimiento) => {
        setDataModal(response => ({ ...response, data: [...response.data, respuesta] }));
        const herramientaEncontrada = herramientasGenerales.elementos.find(her => her.id === respuesta.herramientaId);
        herramientaEncontrada.mantenimientos = [...herramientaEncontrada.mantenimientos, respuesta];
    };

    const seleccionarHerramienta = (estado: boolean, herramientaSeleccionada: IHerramienta) => {
        if (estado) {
            setHerramientasSeleccionadas((herramientas: IHerramienta[]) => [...herramientas, herramientaSeleccionada]);
        } else {
            setHerramientasSeleccionadas((herramientas: IHerramienta[]) =>
                herramientas.filter((her: IHerramienta) => her.id !== herramientaSeleccionada.id)
            );
        }
    };

    const seleccionarTodasHerramientas = (estado: boolean) => {
        if (estado) {
            setHerramientasSeleccionadas([...herramientasGenerales.elementos]);
            herramientasGenerales.elementos.forEach(item => {
                (document.querySelector(`#check-${item.id}`) as HTMLInputElement).checked = estado;
            });
        } else {
            setHerramientasSeleccionadas([]);
            herramientasGenerales.elementos.forEach(item => {
                (document.querySelector(`#check-${item.id}`) as HTMLInputElement).checked = estado;
            });
        }
    };

    const buscarDescripcionCatalogo = (idUnidad: number, catalogo: string = 'unidades') =>
        catalogos[catalogo].find((cat: ICatalogo) => cat.id === idUnidad)?.descripcion;

    const descargarReporte = async () => {
        try {
            setDescargandoReporte(true);
            const response = await getReporteHerramientasService(queryBusqueda, filtroHerramienta);
            descargarArchivo(response, 'ReporteHerramientas.xlsx');
        } catch (ex) {
            setError(ex);
        } finally {
            setDescargandoReporte(false);
        }
    };

    const mostrarModalEliminar = (herramienta: IHerramienta) => {
        setHerramientaPorEliminar(herramienta);
        setShowModalEliminar(true);
    };

    const cerrarModalEliminar = () => {
        setHerramientaPorEliminar({});
        setShowModalEliminar(false);
    };

    const buscarCambioFiltro = () => {
        if (!queryBusqueda || queryBusqueda.length > 2) {
            setCargando(true);
            obtenerHerramientas(1, 25, queryBusqueda, filtroHerramienta);
        }
    };

    useEffect(() => {
        if (!queryBusqueda) {
            buscarCambioFiltro();
        }
    }, [filtroHerramienta, queryBusqueda]);

    useEffect(() => {
        descargarTodosQR();
    }, [paginacion, herramientasSeleccionadas]);

    useEffect(() => {
        obtenerUnidadesMedida();

        return () => {
            setCargando(true);
            setModalCertificacionesData({ show: false, data: {} });
            setShowModalEliminar(false);
            setHerramientasSeleccionadas([]);
            setHerramientaPorEliminar({});
            setCatalogos({
                unidades: [],
                capacidadCilindro: [],
            });
            setModalMantenimiento(false);
            setDataModal({
                idHerramienta: 0,
                nombreHerramienta: '',
                titulo: '',
                data: [],
            });
            setPaginacion({
                numeroPagina: 1,
                paginas: 25,
                masRegistros: true,
            });
            setHerramientasGenerales({
                disponible: 0,
                prestada: 0,
                elementos: [],
            });
            setDescargandoReporte(false);
            setError('');
        };
    }, []);

    return (
        <Container className="d-flex flex-column mt-5" fluid>
            <ErrorAlert />
            <h1 className="text-center mb-5">Herramienta</h1>
            <div className="d-none" id="qr-container"></div>
            <div className="d-none" id="qrs-container"></div>
            <Row className="d-flex align-items-end justify-content-between">
                <Col xs={6} sm={3} md={3} lg={3}>
                    <Form.Group className="d-flex">
                        <InputGroup>
                            <Form.Control
                                name="herramienta"
                                type="search"
                                onChange={({ target }) => setQueryBusqueda(target.value)}
                                onKeyDown={event => {
                                    if (event.keyCode === 13) {
                                        buscarCambioFiltro();
                                    }
                                }}
                                placeholder="Buscar (NC/Herramienta/Modelo/Marca/Estado)"
                            />
                            <Button variant="primary" onClick={() => buscarCambioFiltro()}>
                                <SearchIcon />
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col xs={6} sm={2} md={2} lg={2}>
                    <Form.Group>
                        <Form.Select
                            className="ms-2"
                            onChange={({ target }) => setFiltroHerramienta(+target.value as FiltroHerramienta)}
                            value={filtroHerramienta}
                        >
                            <option value={FiltroHerramienta.Todas}>Todas</option>
                            <option value={FiltroHerramienta.Prestada}>Prestadas</option>
                            <option value={FiltroHerramienta.Resguardo}>Resguardo</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col className="d-flex align-items-end justify-content-around" xs={4} sm={4} md={4} lg={4}>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            id="todos"
                            label="Seleccionar Todo"
                            onChange={({ target }) => seleccionarTodasHerramientas(target.checked)}
                            disabled={!herramientasGenerales.elementos.length}
                        />
                    </Form>
                    {ROLES.ADMINISTRADOR === roleUsuario && (
                        <Button variant="secondary" onClick={descargarReporte} disabled={descargandoReporte}>
                            {descargandoReporte ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    &nbsp;Descargando Reporte
                                </>
                            ) : (
                                'Descargar Reporte'
                            )}
                        </Button>
                    )}
                    <Button onClick={imprimirQRs} disabled={!herramientasSeleccionadas.length}>
                        Descargar QRs
                    </Button>
                </Col>
                <Col className="d-flex align-items-end justify-content-around" xs={8} sm={3} md={3} lg={3}>
                <Link to={AppRoutes.HistorialHerramienta}>
                    <Button variant="primary">Historial Herramientas</Button>
                </Link>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <p className="fw-bold my-0">Disponibles</p>
                        <p className="my-1">{disponibilidadHerramienta.disponibles}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <p className="fw-bold my-0">Prestadas</p>
                        <p className="my-1">{disponibilidadHerramienta.prestadas}</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="overflow-scroll" md={12} sm={12} xs={12}>
                    {cargando ? (
                        <Loading mensaje="Cargando herramientas" />
                    ) : (
                        <InfiniteScroll
                            className="mt-5"
                            dataLength={herramientasGenerales.elementos.length}
                            next={() =>
                                obtenerHerramientas(paginacion.numeroPagina, paginacion.paginas, queryBusqueda, filtroHerramienta)
                            }
                            height={550}
                            loader={
                                herramientasGenerales.elementos.length >= paginacion.paginas &&
                                paginacion.masRegistros && (
                                    <div className="d-flex align-items-center justify-content-center py-4">
                                        <Spinner variant="secondary" animation="border" />
                                        <p className="mx-2 my-0">Cargando Herramientas</p>
                                    </div>
                                )
                            }
                            hasMore={paginacion.masRegistros}
                        >
                            <Table striped hover bordered>
                                <thead>
                                    <tr>
                                        <th>NC</th>
                                        <th>Herramienta</th>
                                        <th>Complemento Nombre</th>
                                        <th>Marca</th>
                                        <th>Modelo</th>
                                        <th>Costo</th>
                                        <th>Estado</th>
                                        <th>Ubicación</th>
                                        <th>Responsable</th>
                                        <th>Resguardo</th>
                                        <th>Mantenimientos</th>
                                        <th>Certificaciones</th>
                                        <th>Descargar QR</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!herramientasGenerales.elementos.length ? (
                                        <tr>
                                            <td colSpan={13}>No hay herramientas registradas.</td>
                                        </tr>
                                    ) : (
                                        herramientasGenerales.elementos.map((her: IHerramienta) => (
                                            <tr key={her.id}>
                                                <td>{her.numeroControl}</td>
                                                <td>{her.herramientaGenerica}</td>
                                                <td>{her.complementoNombre}</td>
                                                <td>{her.marca}</td>
                                                <td>{her.modelo}</td>
                                                <td>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(her.costo)}</td>
                                                <td>{her.estado}</td>
                                                <td>{her.ubicacion}</td>
                                                <td>{her.responsable}</td>
                                                <td>{her.resguardo ? 'Sí' : 'No'}</td>
                                                <td className="text-center">
                                                    {her.numeroSerie && (
                                                        <Button
                                                            onClick={() => abrirModalMantenimientoCertificaciones(her.id, 'Mantenimientos')}
                                                            type="button"
                                                            variant="link"
                                                            className="text-black"
                                                        >
                                                            <AssignmentIcon />
                                                        </Button>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {her.numeroSerie && (
                                                        <Button
                                                            onClick={() => setModalCertificacionesData({ show: true, data: her })}
                                                            type="button"
                                                            variant="link"
                                                            className="text-black"
                                                        >
                                                            <AssignmentIcon />
                                                        </Button>
                                                    )}
                                                </td>
                                                <td className="text-center" style={{ cursor: 'pointer' }}>
                                                    <Form>
                                                        <Form.Check
                                                            className="checkbox-qr"
                                                            type="checkbox"
                                                            id={`check-${her.id}`}
                                                            onChange={({ target }) => seleccionarHerramienta(target.checked, her)}
                                                        />
                                                    </Form>
                                                </td>
                                                <td className="text-right">
                                                    {her.tipoHerramientaId !== 6 && (
                                                        <DehazeIcon
                                                            style={{ cursor: 'pointer' }}
                                                            className="mx-1 text-black"
                                                            onClick={() => {
                                                                const formatoDetalleHerramienta = {
                                                                    ...her,
                                                                    compatibilidadCilindro: buscarDescripcionCatalogo(
                                                                        her.compatibilidadCilindroId,
                                                                        'capacidadCilindro'
                                                                    ),
                                                                    capacidaDepositioUnidad: buscarDescripcionCatalogo(her.capacidadDepositoUnidadId),
                                                                    flujoMaximoPresionNominalUnidad: buscarDescripcionCatalogo(
                                                                        her.flujoMaxPresionNominalUnidadId
                                                                    ),
                                                                    esfuerzoMaximoBombeoUnidad: buscarDescripcionCatalogo(
                                                                        her.esfuerzoMaxBombeoUnidadId
                                                                    ),
                                                                };
                                                                setShowModalDetalle({ estado: true, data: formatoDetalleHerramienta });
                                                            }}
                                                        />
                                                    )}
                                                    {ROLES.ADMINISTRADOR === roleUsuario && (
                                                        <>
                                                            <Link className="text-black p-0" to={`actualizar/${her.id}`}>
                                                                <EditIcon className="mx-1" />
                                                            </Link>
                                                            <Button
                                                                onClick={() => mostrarModalEliminar(her)}
                                                                type="button"
                                                                variant="link"
                                                                className="text-black p-0"
                                                            >
                                                                <DeleteIcon className="mx-1" />
                                                            </Button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </InfiniteScroll>
                    )}
                </Col>
            </Row>
            <ListasHerramientaModal
                estadoModal={modalMantenimiento}
                cambiarEstadoModal={setModalMantenimiento}
                dataModal={dataModal}
                agregarDataModal={mantenimientoCertificacionRecuperado}
            />
            <EliminarHerramientaModal
                show={showModalEliminar}
                herramienta={herramientaPorEliminar}
                onHide={cerrarModalEliminar}
                recuperarIdHerramienta={idHerramientaRecuperada}
            />
            <CertificacionesModal
                show={modalCertificacionesData.show}
                herramienta={modalCertificacionesData.data}
                onClose={() => setModalCertificacionesData({ show: false, data: {} })}
            />
            <DetalleHerramientaModal
                estadoModal={showModalDetalle.estado}
                cambiarEstadoModal={setShowModalDetalle}
                dataHerramienta={showModalDetalle.data}
            />
        </Container>
    );
}
