import { IFotoHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import styles from '@app/styles/components/ImagePicker.module.scss';
import { AddPhotoAlternate, Photo, Replay } from '@mui/icons-material';
import classNames from 'classnames';
import { SyntheticEvent, useRef, useState, useEffect, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';

export type ImagePickerProps = {
    onChange: (images: { imagenUno: IFotoHerramienta | null; imagenDos: IFotoHerramienta | null }) => void;
    images?: (IFotoHerramienta | null)[]; // images array can contain nulls
};

const ImagePicker = ({ images = [], onChange }: ImagePickerProps) => {
    const [img, setImg] = useState<File | null>(null);
    const [imgDos, setImgDos] = useState<File | null>(null);
    const imagenRef = useRef<HTMLInputElement>(null);
    const imagenDosRef = useRef<HTMLInputElement>(null);

    // Crear URLs de previsualizaci�n solo cuando cambian las im�genes
    const imgPreview = img ? URL.createObjectURL(img) : (images[0]?.data as string | null);
    const imgDosPreview = imgDos ? URL.createObjectURL(imgDos) : (images[1]?.data as string | null);

    // Manejar cambios en la primera imagen
    const changeImgUno = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
        const file = currentTarget.files?.[0] ?? null;
        setImg(file);
    }, []);

    // Manejar cambios en la segunda imagen
    const changeImgDos = useCallback(({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
        const file = currentTarget.files?.[0] ?? null;
        setImgDos(file);
    }, []);

    useEffect(() => {
        const imagenUno: IFotoHerramienta | null = img ? { id: images[0]?.id || null, data: img } : null;
        const imagenDos: IFotoHerramienta | null = imgDos ? { id: images[1]?.id || null, data: imgDos } : null;

        // Verificar si hay cambios antes de llamar a onChange
        if (img !== null || imgDos !== null) {
            onChange({ imagenUno, imagenDos });
        }

        // Limpieza de URLs generadas por createObjectURL
        return () => {
            if (imgPreview) URL.revokeObjectURL(imgPreview);
            if (imgDosPreview) URL.revokeObjectURL(imgDosPreview);
        };
    }, [img, imgDos, onChange]); // Reducir la lista de dependencias a lo esencial

    return (
        <div>
            <Form.Label>Fotos de la herramienta</Form.Label>
            <div className="row px-2 mb-4 d-flex flex-column">
                <div
                    className={classNames('position-relative col border d-flex align-items-center justify-content-center me-4 mb-4', styles.image_picker__picture_container)}
                >
                    {imgPreview ? (
                        <img alt="Imagen herramienta" src={imgPreview} className="w-100" />
                    ) : (
                        <Photo sx={{ height: '72px', width: '72px', opacity: '.6' }} />
                    )}
                    <input
                        type="file"
                        className="d-none"
                        accept="image/*"
                        onChange={changeImgUno}
                        ref={imagenRef}
                    />
                    <div className={classNames('d-flex', styles.buttonsOverlay)}>
                        <Button onClick={() => imagenRef.current?.click()} type="button" variant="link" className="col text-white" size="lg">
                            <AddPhotoAlternate />
                        </Button>
                        {img && (
                            <Button
                                onClick={() => {
                                    setImg(null);
                                }}
                                type="button"
                                variant="link"
                                className="col text-white"
                                size="lg"
                            >
                                <Replay />
                            </Button>
                        )}
                    </div>
                </div>
                <div
                    className={classNames('position-relative col border d-flex align-items-center justify-content-center', styles.image_picker__picture_container)}
                >
                    {imgDosPreview ? (
                        <img alt="Imagen herramienta" src={imgDosPreview} className="w-100" />
                    ) : (
                        <Photo sx={{ height: '72px', width: '72px', opacity: '.6' }} />
                    )}
                    <input
                        type="file"
                        className="d-none"
                        accept="image/*"
                        onChange={changeImgDos}
                        ref={imagenDosRef}
                    />
                    <div className={classNames('d-flex', styles.buttonsOverlay)}>
                        <Button onClick={() => imagenDosRef.current?.click()} type="button" variant="link" className="col text-white" size="lg">
                            <AddPhotoAlternate />
                        </Button>
                        {imgDos && (
                            <Button
                                onClick={() => {
                                    setImgDos(null);
                                }}
                                type="button"
                                variant="link"
                                className="col text-white"
                                size="lg"
                            >
                                <Replay />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagePicker;
