import Loading from '@app/components/Loading';
import { useCatalogos } from '@app/hooks';
import useError from '@app/hooks/useError';
import { Catalogo } from '@app/models/enums';
import { IItemCertificacion, IRegistrarCertificacion } from '@app/models/herramienta/ICertificacion';
import { IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { agregarCertificacion } from '@app/services';
import { nuevaCertificacionValidaciones } from '@app/utils/validaciones/certificacion.validaciones';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { format } from 'date-fns';

type FormularioCertificacionesProps = {
  idHerramienta: number;
  respuestaFormulario: Function;
  certificaciones?: IItemCertificacion[];
  herramienta?: Partial<IHerramienta>;
};

export default function FormularioCertificaciones({
  idHerramienta,
  respuestaFormulario,
  herramienta,
}: FormularioCertificacionesProps) {
  const [cargando, setCargando] = useState<{ estado: boolean; mensaje: string }>({ estado: false, mensaje: '' });
  const catalogos = useCatalogos([Catalogo.Proveedores, Catalogo.PeriodosDeCertificacion]);
  const { setError, ErrorAlert } = useError();
  const initialValues: IRegistrarCertificacion = {
    fechaCertificacion: '',
    periodoCertificacion: 0,
    comentarios: '',
    proveedorId: 0,
    herramientaId: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: nuevaCertificacionValidaciones,
    onSubmit: (values: IRegistrarCertificacion) => registrarCertificacion({ ...values, herramientaId: idHerramienta }),
  });

  const registrarCertificacion = async (valores: IRegistrarCertificacion) => {
    try {
      setCargando({ estado: true, mensaje: 'Registrando certificación' });
      const data = await agregarCertificacion(valores);
      respuestaFormulario(data);
      formik.resetForm();
    } catch (ex) {
      const { error } = ex;
      setError(error?.response?.data);
    } finally {
      setCargando({ estado: false, mensaje: '' });
    }
  };

  return (
    <Container className="d-flex flex-column" fluid>
      {cargando.estado && <Loading mensaje={cargando.mensaje} />}
      <ErrorAlert />
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Alert className="p-2" variant="info">
            <p className="text-left">
              <span className="fw-bold">Herramienta:</span> {herramienta.modelo}
            </p>
            <p className="text-left">
              <span className="fw-bold">Anterior Certificación:</span>{' '}
              {herramienta?.ultimaCertificacion ? format(new Date(herramienta.ultimaCertificacion), 'dd/MM/yyyy') : ''}
            </p>
            <p className="text-left">
              <span className="fw-bold">Próxima Certificación:</span>{' '}
              {herramienta?.proximaCertificacion
                ? format(new Date(herramienta.proximaCertificacion), 'dd/MM/yyyy')
                : ''}
            </p>
          </Alert>
        </Col>
      </Row>
      <form onSubmit={formik.handleSubmit}>
        <Row className="my-4">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Proveedor Externo</Form.Label>
              <Form.Control
                name="proveedorId"
                isInvalid={!!formik.errors.proveedorId}
                value={formik.values.proveedorId}
                onChange={formik.handleChange}
                as="select"
              >
                <option>
                  {catalogos.status === 'pending' ? 'Cargando proveedores...' : 'Seleccione Proveedor Externo'}
                </option>
                {catalogos.data?.Proveedores.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.proveedorId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Fecha Certificación</Form.Label>
              <Form.Control
                name="fechaCertificacion"
                isInvalid={!!formik.errors.fechaCertificacion}
                value={formik.values.fechaCertificacion}
                onChange={formik.handleChange}
                type="date"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.fechaCertificacion}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Comentarios</Form.Label>
              <Form.Control
                name="comentarios"
                isInvalid={!!formik.errors.comentarios}
                value={formik.values.comentarios}
                onChange={formik.handleChange}
                as="textarea"
                rows={5}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.comentarios}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="d-flex align-items-end justify-content-end" md={12} sm={12} xs={12}>
            <Button variant="secondary" type="submit">
              Guardar Certificación
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
