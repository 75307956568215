import Loading from '@app/components/Loading';
import useError from '@app/hooks/useError';
import { modalLectorQRProps } from '@app/models/types/modal.type';
import { getHerramientaByNcService } from '@app/services';
import { qrValidaciones } from '@app/utils/validaciones/qr.validaciones';
import { useFormik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

export default function LectorQRModal(props: modalLectorQRProps) {
  const { estadoModal, cambiarEstadoModal, recuperarHerramienta, tipoMovimiento } = props;
  const [cargando, setCargando] = useState<boolean>(false);
  const { setError, ErrorAlert } = useError();
  const refNumeroControl = useRef<HTMLInputElement | null>(null);
  const formik = useFormik({
    initialValues: {
      numeroControlHerramienta: '',
    },
    validationSchema: qrValidaciones,
    onSubmit: (values: { numeroControlHerramienta: string }) => buscarHerramienta(values.numeroControlHerramienta),
  });

  const buscarHerramienta = async (numeroControl: string) => {
    try {
      setError('');
      setCargando(true);
      const data = await getHerramientaByNcService(numeroControl);
      if (typeof data === 'string') {
        throw new Error('La herramienta no se encontró.');
      } else {
        //i Si es salida y tiene responsable
        if (!esEntrada && !!data.responsable) {
          throw new Error('La herramienta ya se encuentra prestada.');
        }
        //i Si es salida y tiene resguardo
        else if (!esEntrada && !!data.resguardo) {
          throw new Error('La herramienta tiene resguardo.');
        } else if (esEntrada && !!!data.responsable) {
          throw new Error('La herramienta se encuentra en almacén.');
        } else {
          recuperarHerramienta(data);
          cambiarEstadoModal(false);
        }
      }
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando(false);
    }
  };

  const esEntrada = useMemo(() => tipoMovimiento === 'entrada', [tipoMovimiento]);

  useEffect(() => {
    if (!estadoModal) {
      setError('');
      setCargando(false);
      formik.resetForm();
    } else {
      refNumeroControl?.current.focus();
    }
  }, [estadoModal]);

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Herramienta Almacén</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cargando && <Loading mensaje="Buscando herramienta" />}
        <ErrorAlert />
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Form.Group>
                <Form.Label>Número Control</Form.Label>
                <Form.Control
                  isInvalid={!!formik.errors.numeroControlHerramienta}
                  name="numeroControlHerramienta"
                  value={formik.values.numeroControlHerramienta}
                  onChange={formik.handleChange}
                  placeholder="Número Control"
                  onBlur={() => {
                    refNumeroControl?.current.focus();
                  }}
                  ref={refNumeroControl}
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {formik.errors.numeroControlHerramienta}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="d-grid gap-2 my-2" xs={12} sm={12} md={12} lg={12}>
              <Button variant="primary" type="submit">
                Buscar Herramienta
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
