import { ReactComponent as PecoLogo } from '@app/assets/images/logo-full.svg';
import Loading from '@app/components/Loading';
import Notificacion from '@app/components/Notificacion';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo, Notificaciones as NotificacionesEnum } from '@app/models/enums';
import { INotificacion } from '@app/models/notificaciones/notificacion';
import { getCatalogo } from '@app/services';
import { getNotificacionesService } from '@app/services/notificaciones.service';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
export default function Notificaciones() {
  const [cargando, setCargando] = useState<boolean>(false);
  const [notificaciones, setNotificaciones] = useState<{
    listaNotificaciones: INotificacion[];
    tipoNotificaciones: ICatalogoGenerico[];
  }>({
    listaNotificaciones: [],
    tipoNotificaciones: [],
  });
  const { setError, ErrorAlert } = useError();

  const obtenerNotificaciones = async () => {
    try {
      setCargando(true);
      const notificaciones = await getNotificacionesService();
      const tipoNotificaciones = await getCatalogo(Catalogo.TipoNotificacion);
      setNotificaciones({
        listaNotificaciones: notificaciones ? notificaciones : [],
        tipoNotificaciones: tipoNotificaciones ? tipoNotificaciones : [],
      });
    } catch (ex) {
      setError(ex?.response?.data);
    } finally {
      setCargando(false);
    }
  };

  const notificacionesSegmentadas = useMemo(() => {
    const defaultNotificaion = {
      id: 0,
      concepto: '',
      fechaNotificacion: '',
      tipoNotificacionId: 0,
      notificacionesHerramientas: [],
    };
    const { listaNotificaciones } = notificaciones;
    const notificacionesSegment = {
      proximaBaja:
        listaNotificaciones.find(nt => nt.tipoNotificacionId === NotificacionesEnum.Correctivo) || defaultNotificaion,
      mantenimiento:
        listaNotificaciones.find(nt => nt.tipoNotificacionId === NotificacionesEnum.Preventivo) || defaultNotificaion,
      certificaciones:
        listaNotificaciones.find(nt => nt.tipoNotificacionId === NotificacionesEnum.Certificacion) ||
        defaultNotificaion,
    };
    return notificacionesSegment;
  }, [notificaciones.listaNotificaciones]);

  useEffect(() => {
    obtenerNotificaciones();
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <ErrorAlert />
      <Row className="mt-3">
        <Col className="my-0 mx-auto d-flex align-items-center justify-content-around" xs={8} sm={8} md={8} lg={8}>
          <Link to={AppRoutes.Entradas}>
            <Button size="lg" variant="secondary">
              Entrega de Material
            </Button>
          </Link>
          <PecoLogo width={200} />
          <Link to={AppRoutes.Salidas}>
            <Button variant="secondary" size="lg">
              Salida de Material
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mt-5 mb-3">
        {cargando ? (
          <Loading mensaje="Cargando notificaciones" />
        ) : (
          <>
            <Col xs={4} sm={4} md={4}>
              <Notificacion
                datos={{
                  ...notificacionesSegmentadas.proximaBaja,
                  tiposNotificaciones: notificaciones.tipoNotificaciones,
                }}
              />
            </Col>
            <Col xs={4} sm={4} md={4}>
              <Notificacion
                datos={{
                  ...notificacionesSegmentadas.mantenimiento,
                  tiposNotificaciones: notificaciones.tipoNotificaciones,
                }}
              />
            </Col>
            <Col xs={4} sm={4} md={4}>
              <Notificacion
                datos={{
                  ...notificacionesSegmentadas.certificaciones,
                  tiposNotificaciones: notificaciones.tipoNotificaciones,
                }}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
}
