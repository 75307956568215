import { eliminarNotificacionModalProps } from '../../models/types/modal.type';
import { Button, Modal } from 'react-bootstrap';

export default function EliminarAlmacenModal(props: eliminarNotificacionModalProps) {
  const { estadoModal, textoBody, cambiarEstadoModal, elemento, notificacion, recuperarData, tituloModal } = props;

  const onDelete = () => {
    try {
      recuperarData(elemento);
    } catch (err) {
      throw err;
    }
  };

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal({ estado: false, [notificacion]: elemento })}>
      <Modal.Header closeButton>
        <Modal.Title>{tituloModal}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="eliminar-almacen__body">
        <h5 className="fw-normal">
          ¿Está seguro que desea eliminar <span className="fw-bold"> {textoBody} </span>?
        </h5>
      </Modal.Body>
      <Modal.Footer className="eliminar-almacen__footer">
        <Button variant="secondary" onClick={() => cambiarEstadoModal({ estado: false, [notificacion]: elemento })}>
          Cancelar
        </Button>
        <Button variant="primary" type="submit" onClick={onDelete}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
