export const enum TipoHerramienta {
  Neumatica = 1,
  Electrica,
  Gases,
  Hidraulica,
  Mecanica,
  Menores,
  Izaje,
  Soldadura,
}

export function getNombreTipoHerramienta(id: TipoHerramienta): string {
  switch (id) {
    case TipoHerramienta.Neumatica:
      return 'Neumática';
    case TipoHerramienta.Electrica:
      return 'Eléctrica';
    case TipoHerramienta.Gases:
      return 'Gases';
    case TipoHerramienta.Hidraulica:
      return 'Hidráulica';
    case TipoHerramienta.Mecanica:
      return 'Mecánica';
    case TipoHerramienta.Izaje:
      return 'Izaje';
    case TipoHerramienta.Soldadura:
      return 'Soldadura';
    default:
      return 'Menores';
  }
}
