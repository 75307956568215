import FormHerramientaGeneral from '@app/components/forms/FormHerramientaGeneral';
import FormHerramientaHidraulica from '@app/components/forms/FormHerramientaHidraulica';
import FormHerramientaMecanica from '@app/components/forms/FormHerramientaMecanica';
import FormHerramientaMenor from '@app/components/forms/FormHerramientaMenor';
import ImagePicker from '@app/components/ImagePicker';
import Loading from '@app/components/Loading';
import Title from '@app/components/Title';
import { AppRoutes } from '@app/contants/app-routes';
import { IAsyncData } from '@app/models';
import { TipoHerramienta } from '@app/models/enums';
import { IFotoHerramienta, IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { getFotosHerramientaById, getHerramientaById, updateHerramienta, upsertFotoHerramienta } from '@app/services';
import {
  herramientaHidraulicaSchema,
  herramientaMecanicaSchema,
  herramientaMenorSchema,
  herramientaSchema,
} from '@app/utils/validaciones/herramienta.schema';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
export default function ActualizarHerramienta() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [guardando, setGuardando] = useState(false);
  const [herramienta, setHerramienta] = useState<IAsyncData<IHerramienta>>({ status: 'pending' });
  const [fotos, setFotos] = useState<IAsyncData<IFotoHerramienta[]>>({ status: 'pending' });
  const [nuevasFotos, setNuevasFotos] = useState<{ imagenUno: IFotoHerramienta; imagenDos: IFotoHerramienta }>({
    imagenUno: null,
    imagenDos: null,
  });

  async function submitForm(data: IHerramienta) {
    setGuardando(true);
    try {
      let requests: Promise<any>[] = [updateHerramienta(data)];

      if (nuevasFotos.imagenUno) {
        requests.push(upsertFotoHerramienta(id, nuevasFotos.imagenUno));
      }
      if (nuevasFotos.imagenDos) {
        requests.push(upsertFotoHerramienta(id, nuevasFotos.imagenDos));
      }

      await Promise.all(requests);

      navigate(AppRoutes.Herramienta);
    } catch (ex) {
      console.log(ex);
    } finally {
      setGuardando(false);
    }
  }

  const validationSchema = useMemo(() => {
    switch (herramienta.data?.tipoHerramientaId) {
      case TipoHerramienta.Mecanica:
        return herramientaMecanicaSchema;
      case TipoHerramienta.Menores:
        return herramientaMenorSchema;
      case TipoHerramienta.Hidraulica:
        return herramientaHidraulicaSchema;
      default:
        return herramientaSchema;
    }
  }, [herramienta.data?.tipoHerramientaId]);

  const formik = useFormik({
    initialValues: {
      ...(herramienta?.data || {}),
    },
    validationSchema,
    onSubmit: submitForm,
  });
  const { handleSubmit, setValues } = formik;

  useMemo(() => {
    if (herramienta?.data) {
      const formatoFechaCompra = herramienta.data.fechaCompra.slice(0, 10);
      setValues({ ...herramienta.data, fechaCompra: formatoFechaCompra });
    }
  }, [herramienta?.data, id]);

  function renderForm() {
    if (herramienta.status === 'fulfilled' || fotos.status === 'fulfilled') {
      switch (herramienta.data?.tipoHerramientaId) {
        case TipoHerramienta.Mecanica:
          return (
            <FormHerramientaMecanica herramientaGenericaId={id} herramienta={herramienta.data} onSubmit={submitForm} />
          );
        case TipoHerramienta.Menores:
          return (
            <FormHerramientaMenor herramientaGenericaId={id} herramienta={herramienta.data} onSubmit={submitForm} />
          );
        case TipoHerramienta.Hidraulica:
          return (
            <FormHerramientaHidraulica
              herramientaGenericaId={id}
              herramienta={herramienta.data}
              onSubmit={submitForm}
            />
          );
        default:
          return (
            <FormHerramientaGeneral herramientaGenericaId={id} herramienta={herramienta.data} onSubmit={submitForm} />
          );
      }
    } else if (herramienta.status === 'rejected' || fotos.status === 'rejected') {
      return (
        <Alert variant="danger" dismissible>
          Ocurrió un error al intentar obtener los detalles del tipo de herrramienta. Por favor intente de nuevo.
        </Alert>
      );
    } else {
      return <Loading mensaje="Cargando" />;
    }
  }

  useEffect(() => {
    getHerramientaById(id)
      .then(res => {
        setHerramienta({ status: 'fulfilled', data: res });
      })
      .catch(() => {
        setHerramienta({ status: 'rejected' });
      });

    getFotosHerramientaById(id)
      .then(res => {
        setFotos({ status: 'fulfilled', data: res });
      })
      .catch(() => {
        setFotos({ status: 'rejected' });
      });
  }, [id]);

  return (
    <Container className="d-flex flex-column mt-5 mb-4" fluid>
      <Title titulo="Editar Herramienta" ruta={AppRoutes.Herramienta} />
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Row className="w-100 mt-2 mb-3 border-bottom border-dark border-2 pb-2">
            <Col xs={4} sm={4} md={4} className="d-flex align-items-center justify-content-around">
              <p>
                Número de Control: <span className="fw-bold ms-1">{herramienta.data?.numeroControl}</span>
              </p>
            </Col>
            <Col xs={4} sm={4} md={4} className="d-flex align-items-center justify-content-around">
              <p>
                Nombre: <span className="fw-bold ms-1">{herramienta.data?.herramientaGenerica}</span>
              </p>
            </Col>
            <Col xs={4} sm={4} md={4} className="d-flex align-items-start justify-content-center">
              <Button type="submit" variant="primary" disabled={guardando}>
                {guardando ? <Spinner animation="border" /> : 'Guardar Herramienta'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={9} sm={9} md={9}>
              {renderForm()}
            </Col>
            <Col xs={3} sm={3} md={3}>
              <ImagePicker images={fotos?.data || []} onChange={setNuevasFotos} />
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </Container>
  );
}
