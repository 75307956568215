import { ReactComponent as Logo } from '@app/assets/images/logo.svg';
import useError from '@app/hooks/useError';
import { ILogin } from '@app/models/login/ILogin';
import { loginService } from '@app/services/login.service';
import { loginValidaciones } from '@app/utils/validaciones/login.validaciones';
import { useFormik } from 'formik';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import useSesion from '@app/hooks/useSesion';

export default function Login() {
  const [cargando, setCargando] = useState<boolean>(false);
  const { setUsuario } = useSesion();
  const { setError, ErrorAlert } = useError();
  const initialValues: ILogin = {
    userName: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidaciones,
    onSubmit: async (values: ILogin) => {
      setCargando(true);
      const { error, data } = await loginService(values);
      if (data) {
        setUsuario(data.usuario);
      } else {
        setError(error?.response?.data);
      }
      setCargando(false);
    },
  });

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-lg-6 d-flex justify-content-center">
          <Logo width="80%" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xs-12 col-lg-6 mt-5">
          <Card>
            <Card.Body className="p-5">
              <ErrorAlert />
              <form onSubmit={formik.handleSubmit}>
                <Form.Group className="my-4">
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control
                    name="userName"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.userName}
                    placeholder="Usuario"
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {formik.errors.userName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-4">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.password}
                    placeholder="Contraseña"
                    type="password"
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button className="w-100 my-3" variant="primary" type="submit" disabled={cargando}>
                  {cargando ? <Spinner animation="border" /> : 'Iniciar Sesión'}
                </Button>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
