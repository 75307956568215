import { INotificacion } from '@app/models/notificaciones/notificacion';
import axios from '@app/utils/request/axios';

export async function getNotificacionesService() {
  try {
    const { data } = await axios.get<INotificacion[]>('Notificacion');
    return data;
  } catch (e) {}
}

export const deleteNotificacionHerramienta = async (herramientaId: number, notificacionId: number) => {
  try {
    const response = await axios.delete(`Notificacion/${herramientaId}/${notificacionId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};