import { ITrabajadorEntradaSalidaDetalle } from '@app/models/entradas/ITrabajadorEntradaSalida';

export const DEFAULT_DETALLA_ES: ITrabajadorEntradaSalidaDetalle = {
  codigocol: 0,
  correo: '',
  departamento: '',
  empresa: '',
  gerencia: '',
  nivelFirma: '',
  nombre: '',
  pueId: 0,
  puesto: '',
  tipo: '',
  unoId: 0,
  usuarioId: '',
  tarjeta: 0,
};
