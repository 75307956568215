import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Loading from '../../components/Loading';
import MensajeComponent from '../../components/Mensaje';
import Title from '../../components/Title';
import { AppRoutes } from '../../contants/app-routes';
import useError from '../../hooks/useError';
import { ICargando } from '../../models/ICargando';
import { IMensaje } from '../../models/IMensaje';
import { IUsuario } from '../../models/usuarios/IUsuario';
import {
  activarUsuarioExternoService,
  getUsuariosService,
  resetPasswordUsuarioExternoService,
} from '../../services/usuarios.service';

import '../../styles/pages/Usuarios.scss';

export default function Usuarios() {
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuariosAux, setUsuariosAux] = useState<IUsuario[]>([]);
  const [itemBusqueda, setItemBusqueda] = useState<string>('');
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const [mensaje, setMensaje] = useState<IMensaje>({
    abierto: false,
    mensaje: '',
  });
  const { ErrorAlert, setError } = useError();
  const navigate = useNavigate();

  const buscarUsuario = (userParam: string) => {
    setItemBusqueda(userParam);
    const usuariosFiltrados = usuariosAux.filter(
      (usr: IUsuario) =>
        usr.userName.toLowerCase().includes(userParam.toLowerCase()) ||
        usr.roles.join(',').toLowerCase().includes(userParam.toLowerCase())
    );
    setUsuarios(usuariosFiltrados);
  };

  const obtenerUsuarios = async () => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo usuarios',
      });
      const response = await getUsuariosService();
      setUsuarios(response ?? []);
      setUsuariosAux(response ?? []);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const resetPasswordUsuarioExterno = async (idUsuario: string) => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Cambiando contraseña...',
      });
      await resetPasswordUsuarioExternoService(idUsuario);
      setMensaje({ abierto: true, mensaje: 'Se reseteo la contraseña correctamente.' });
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  const habilitarDeshabilitarUsuarioExterno = async (idUsuario: string, estado: boolean) => {
    try {
      setCargando({
        cargando: true,
        mensaje: 'Cambiando estado usuario...',
      });
      const { active } = await activarUsuarioExternoService(idUsuario, estado);
      const usuariosModificados: IUsuario[] = usuarios.map((usr: IUsuario) =>
        usr.id === idUsuario ? { ...usr, active } : usr
      );
      setUsuarios(usuariosModificados);
      setUsuariosAux(usuariosModificados);
      setMensaje({
        abierto: true,
        mensaje: `Se ${active ? 'habilitó' : 'deshabilitó'} ${
          usuarios.find(usuario => usuario.id === idUsuario)?.userName
        }.`,
      });
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  };

  useEffect(() => {
    obtenerUsuarios();
    return () => {
      setCargando({
        cargando: false,
        mensaje: '',
      });
      setItemBusqueda('');
      setUsuarios([]);
      setUsuariosAux([]);
    };
  }, []);

  return (
    <Container className="usuarios d-flex flex-column mt-5">
      <ErrorAlert />
      {mensaje.abierto && (
        <MensajeComponent mensaje={mensaje.mensaje} abierto={mensaje.abierto} estadoMensaje={setMensaje} />
      )}
      <Title titulo="Usuarios" ruta="" />
      {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
      <Row className="mt-5">
        <Col className="my-3 d-flex align-items-end justify-content-between" xs={7} sm={6} md={6} lg={6}>
          <InputGroup>
            <Form.Control
              name="buscar"
              value={itemBusqueda}
              onChange={({ target }) => buscarUsuario(target.value)}
              placeholder="Usuario"
            />
            <InputGroup.Text id="search">
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="my-3 d-flex align-items-end justify-content-end" xs={5} sm={6} md={6} lg={6}>
          <Button
            className="text-white"
            variant="secondary"
            onClick={() => navigate(`${AppRoutes.RegistrarUsuario}/interno`)}
          >
            Nuevo Usuario Interno
          </Button>
          &nbsp;&nbsp;
          <Button
            className="text-white"
            variant="secondary"
            onClick={() => navigate(`${AppRoutes.RegistrarUsuario}/externo`)}
          >
            Nuevo Usuario Externo
          </Button>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Usuario</th>
                <th>Correo Electrónico</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!!usuarios.length ? (
                <>
                  {usuarios.map((usr: IUsuario) => (
                    <tr key={usr.id}>
                      <td>{usr.tipo}</td>
                      <td>{usr.userName}</td>
                      <td>{usr.email}</td>
                      <td>
                        <EditIcon
                          className="usuarios__accion"
                          onClick={() => navigate(`${AppRoutes.EditarUsuario}/${usr.tipo}/${usr.id}`)}
                        />
                        {usr.tipo.toLowerCase() === 'externo' && (
                          <>
                            <RefreshIcon
                              className="usuarios__accion"
                              onClick={() => resetPasswordUsuarioExterno(usr.id)}
                            />
                            {usr.active ? (
                              <LockOpenIcon
                                className="usuarios__accion"
                                onClick={() => habilitarDeshabilitarUsuarioExterno(usr.id, !usr.active)}
                              />
                            ) : (
                              <LockIcon
                                className="usuarios__accion"
                                onClick={() => habilitarDeshabilitarUsuarioExterno(usr.id, !usr.active)}
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={4}>No hay usuarios registrados</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
