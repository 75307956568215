import Logo from '@app/assets/images/logo.png';
import { AppRoutes } from '@app/contants/app-routes';
import useSesion from '@app/hooks/useSesion';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../styles/components/navMenu.module.scss';
import { ROLES } from '@app/models/enums';

export default function NavMenu() {
  const { setUsuario } = useSesion();
  const roleUsuario = JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase();
  const nombreUsuario = JSON.parse(localStorage.getItem('credenciales'))?.usuario;
  const cerrarSesion = () => {
    setUsuario(null);
    localStorage.removeItem('credenciales');
  };

  return (
    <Navbar bg="primary" expand="lg" className="px-3" variant="dark" sticky="top" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand>
          <img src={Logo} alt="Logo Peña colorada" height="57" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="w-100 d-flex align-items-center justify-content-between navmenu__nav-items">
            <Nav>
              <Nav.Link as={Link} to={AppRoutes.Notificaciones}>
                Notificaciones
              </Nav.Link>
              {roleUsuario !== ROLES.USUARIOEXTERNO && (
                <Nav.Link as={Link} to={AppRoutes.CatalogoAlmacen}>
                  Catálogo de Almacén
                </Nav.Link>
              )}
              <Nav.Link as={Link} to={AppRoutes.Herramienta}>
                Herramienta
              </Nav.Link>
              <Nav.Link as={Link} to={AppRoutes.PrestamosRecientes}>
                Préstamos
              </Nav.Link>
              {roleUsuario !== 'usuarioexterno' && (
                <>
                  <Nav.Link as={Link} to={AppRoutes.Catalogos}>
                    Catálogos
                  </Nav.Link>
                  <NavDropdown.Divider />
                  <Nav.Link as={Link} to={AppRoutes.Preferencias}>
                    Preferencias
                  </Nav.Link>
                  {roleUsuario !== ROLES.USUARIOEXTERNO && (
                  <Nav.Link as={Link} to={AppRoutes.Usuarios}>
                    Usuarios
                  </Nav.Link>
                  )}
                </>
              )}
            </Nav>
            <Nav>
              <NavDropdown title={nombreUsuario} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => cerrarSesion()}>Cerrar Sesión</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
