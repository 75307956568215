import Loading from '@app/components/Loading';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo } from '@app/models/enums';
import { IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { IRegistrarMantenimiento } from '@app/models/herramienta/IMantenimiento';
import { getCatalogo } from '@app/services';
import { agregarMantenimiento, getHerramientas } from '@app/services/herramienta.service';
import { nuevoMantenimientoValidaciones } from '@app/utils/validaciones/mantenimiento.validaciones';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router-dom';

export default function Mantenimiento() {
  const [tiposMantenimiento, setTiposMantenimiento] = useState<ICatalogoGenerico[]>([]);
  const [herramientas, setHerramientas] = useState<IHerramienta[]>([]);
  const [herramientaSeleccionada, setHerramientaSeleccionada] = useState<IHerramienta[]>([]);
  const [cargando, setCargando] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();
  const initialValues: IRegistrarMantenimiento = {
    herramientaId: 0,
    fechaMantenimiento: '',
    proveedorId: undefined,
    tipoDeMantenimientoId: 0,
    comentarios: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: nuevoMantenimientoValidaciones,
    onSubmit: (values: IRegistrarMantenimiento) => registrarMantenimiento(values),
  });

  const registrarMantenimiento = async (valores: IRegistrarMantenimiento) => {
    try {
      setCargando(true);
      await agregarMantenimiento(valores);
      navigate(AppRoutes.Herramienta);
    } catch (ex) {
      const { error } = ex;
      setError(error?.response?.data);
    } finally {
      setCargando(false);
    }
  };

  const obtenerTipoMantenimiento = async () => {
    try {
      const response = await getCatalogo(Catalogo.TiposDeMantenimiento);
      setTiposMantenimiento(response);
    } catch (ex) {
      console.log(ex);
    }
  };

  const obtenerHerramientas = async () => {
    try {
      const data = await getHerramientas(0, 0);
      setHerramientas(data.elementos);
    } catch (ex) {
      console.log(ex);
    }
  };

  const seleccionarHerramienta = response => {
    setHerramientaSeleccionada(response);
    // if (response.length) {
    //   const [result] = response;
    //   formik.handleChange(result.modelo);
    //   formik.setValues(values => ({
    //     ...values,
    //     mantenimientoAnterior: establecerUltimoMantenimiento(result),
    //     herramientaId: `${result.id}`,
    //   }));
    // } else {
    //   formik.handleChange('');
    //   formik.setValues(values => ({
    //     ...values,
    //     mantenimientoAnterior: '',
    //     herramientaId: '0',
    //   }));
    // }
  };

  // const establecerUltimoMantenimiento = herramienta => {
  //   const { mantenimientos } = herramienta;
  //   if (mantenimientos.length) {
  //     const { ultimoMantenimiento } = mantenimientos[mantenimientos.length - 1];
  //     return ultimoMantenimiento.slice(0, 10);
  //   } else if (herramienta.fechaCompra) {
  //     return herramienta.fechaCompra.slice(0, 10);
  //   }
  //   return '';
  // };

  useEffect(() => {
    obtenerTipoMantenimiento();
    obtenerHerramientas();
  }, []);

  useEffect(() => {
    return () => {
      setTiposMantenimiento([]);
      setHerramientas([]);
      setHerramientaSeleccionada([]);
      setCargando(false);
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      {cargando && <Loading mensaje="Registrando mantenimiento" />}
      <ErrorAlert />
      <h1 className="text-center mb-5">Mantenimiento</h1>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Herramienta</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="modelo"
                onChange={event => seleccionarHerramienta(event)}
                options={herramientas}
                placeholder="Selecciona una herramienta"
                selected={herramientaSeleccionada}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.herramientaId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Responsable</Form.Label>
              <Form.Control
                name="proveedorId"
                isInvalid={!!formik.errors.proveedorId}
                value={formik.values.proveedorId}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={0}>Seleccione Responsable</option>
                <option value={1}>Primer Responsable</option>
                <option value={2}>Segundo Responsable</option>
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.proveedorId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Tipo de Mantenimiento</Form.Label>
              <Form.Control
                name="tipoDeMantenimientoId"
                isInvalid={!!formik.errors.tipoDeMantenimientoId}
                value={formik.values.tipoDeMantenimientoId}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={0}>Seleccione Tipo de Mantenimiento</option>
                {tiposMantenimiento.map((mant: ICatalogoGenerico) => (
                  <option key={mant.id} value={mant.id}>
                    {mant.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.tipoDeMantenimientoId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* <Row className="my-4">
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Mantenimiento Anterior</Form.Label>
              <Form.Control
                name="mantenimientoAnterior"
                isInvalid={!!formik.errors.mantenimientoAnterior}
                value={formik.values.mantenimientoAnterior}
                onChange={formik.handleChange}
                type="date"
                disabled
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.mantenimientoAnterior}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Próximo Mantenimiento</Form.Label>
              <Form.Control
                name="ultimoMantenimiento"
                isInvalid={!!formik.errors.ultimoMantenimiento}
                value={formik.values.ultimoMantenimiento}
                onChange={formik.handleChange}
                type="date"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.ultimoMantenimiento}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row> */}
        <Row className="my-4">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Comentarios</Form.Label>
              <Form.Control
                name="comentarios"
                isInvalid={!!formik.errors.comentarios}
                value={formik.values.comentarios}
                onChange={formik.handleChange}
                as="textarea"
                rows={5}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.comentarios}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="d-flex align-items-end justify-content-end" md={12} sm={12} xs={12}>
            <Button variant="secondary" type="submit">
              Guardar Mantenimiento
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
