import { IRemitentePreferencias } from '@app/models/preferencias/IRemitentePreferencias';
import {
  IAgregarDestinatarioPreferencias,
  IDestinatarioPreferencias,
} from '@app/models/preferencias/IDestinatarioPreferencias';
import axios from '@app/utils/request/axios';

export async function obtenerDestinatariosService() {
  try {
    const { data } = await axios.get<IDestinatarioPreferencias[]>('CorreosNotificaciones');
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function registrarDestinatarioService(destinatario: IAgregarDestinatarioPreferencias) {
  try {
    const { data } = await axios.post<IDestinatarioPreferencias>('CorreosNotificaciones', destinatario);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function editarDestinatarioService(destinatario: IAgregarDestinatarioPreferencias, id: number) {
  try {
    const { data } = await axios.put<IDestinatarioPreferencias>(`CorreosNotificaciones/${id}`, destinatario);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function eliminarDestinatarioService(idDestinatario: number) {
  try {
    const { data } = await axios.delete<IDestinatarioPreferencias>(`CorreosNotificaciones/${idDestinatario}`);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function obtenerRemitenteService() {
  try {
    const { data } = await axios.get<IRemitentePreferencias>('Preferencia/GetRemitente');
    return data;
  } catch (ex) {
    throw ex;
  }
}

export async function actualizarRemitenteService(request: IRemitentePreferencias) {
  try {
    const { data } = await axios.put<IRemitentePreferencias>('Preferencia/PutRemitente', request);
    return data;
  } catch (ex) {
    throw ex;
  }
}
