import { ICrearHerramientaGenerica } from '@app/models/herramienta';
import axios from '@app/utils/request/axios';

export const catalogoListaService = async () => {
  try {
    const { data } = await axios.get('HerramientaGenerica');
    return { data, error: '' };
  } catch (ex) {
    return { data: [], error: ex?.message };
  }
};

export const getHerramientaGenericaByIdService = async (idHerrmaienta: number) => {
  try {
    const { data } = await axios.get(`HerramientaGenerica/${idHerrmaienta}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export async function postCrearHerramientaGenerica(request: ICrearHerramientaGenerica) {
  try {
    const { data } = await axios.post('HerramientaGenerica', request);
    return data;
  } catch (ex) {
    throw ex?.response?.data?.message || 'Error al registrar la herramienta genérica.';
  }
}

export async function editHerramientaGenerica(idHerramienta: number, request: ICrearHerramientaGenerica) {
  try {
    const { data } = await axios.put(`HerramientaGenerica/${idHerramienta}`, request);
    return data;
  } catch (ex) {
    throw ex?.response?.data?.message || 'Error al registrar la herramienta genérica.';
  }
}

export async function deleteHerramientaGenerica(idHerramienta: number) {
  try {
    const { data } = await axios.delete(`HerramientaGenerica/${idHerramienta}`);
    return data;
  } catch (ex) {
    throw ex?.response?.data?.message || 'Error al registrar la herramienta genérica.';
  }
}

export async function getNumeroDeControl(herramientaGenericaId: string | number) {
  try {
    const { data } = await axios.get<string>(`HerramientaGenerica/NumeroControl/${herramientaGenericaId}`, {
      responseType: 'text',
    });
    return data;
  } catch (ex) {
    throw ex.errors;
  }
}

export async function updateHerramientaEliminada(idHerramienta: number, formData: FormData) {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const { data } = await axios.put(`Herramientas/${idHerramienta}/eliminado`, formData, config);
    return data;
  } catch (ex) {
    throw ex;
  }
  
}