import Title from '@app/components/Title';
import useError from '@app/hooks/useError';
import { IAsyncData } from '@app/models';
import { IHerramientaMovimiento, IMovimiento } from '@app/models/prestamos/IPrestamo';
import { getMovimientoById } from '@app/services/movimientos.service';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function DetallePrestamo() {
  const [movimiento, setMovimiento] = useState<IAsyncData<IMovimiento>>({ status: 'pending' });
  const { setError, ErrorAlert } = useError();
  const { idPrestamo } = useParams();

  const obtenerDetallePrestamo = async () => {
    try {
      //i Se llama a el endpoint correspondiente
      getMovimientoById(idPrestamo)
        .then(res => {
          res.fecha = new Date(res.fecha);
          if (window.location.pathname.toLowerCase().includes('/prestamos/incompletos/')) {
            //i Se filtran las herramientas que no se han entregado
            setMovimiento({
              status: 'fulfilled',
              data: {
                ...res,
                registroHerramientas: res?.registroHerramientas.filter((reg: IHerramientaMovimiento) => !reg.entregado),
              },
            });
          } else {
            setMovimiento({ status: 'fulfilled', data: res });
          }
        })
        .catch(ex => {
          console.error(ex);
          setMovimiento({ status: 'rejected' });
        });
    } catch (ex) {
      setError(ex?.response?.data);
    }
  };

  const rutaRetorno = (path: string): { ruta: string; titulo: string } => {
    if (path.toLocaleLowerCase().includes('/prestamos/reciente/')) {
      return {
        ruta: '/prestamos/recientes',
        titulo: 'Préstamos Recientes de Herramienta',
      };
    } else if (path.toLocaleLowerCase().includes('/prestamos/historico/')) {
      return {
        ruta: '/prestamos/historico',
        titulo: 'Historial Préstamos de Herramienta',
      };
    } else if (path.toLocaleLowerCase().includes('/prestamos/incompletos/')) {
      return {
        ruta: '/prestamos/incompletos',
        titulo: 'Préstamos de Herramienta Pendientes',
      };
    } else {
      return {
        ruta: '/prestamos/recientes',
        titulo: 'Préstamos Recientes de Herramienta',
      };
    }
  };

  useEffect(() => {
    obtenerDetallePrestamo();
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <ErrorAlert />
      <Title titulo={rutaRetorno(window.location.pathname).titulo} ruta={rutaRetorno(window.location.pathname).ruta} />
      <Row className="my-4">
        <Col xs={12} sm={12} md={12} lg={12} className="w-100 d-flex align-items-center justify-content-start fs-6">
          <p className="mr-4">
            <span className="fw-bold">No.Tarjeta:</span> {movimiento.data?.numeroTarjeta}
          </p>
          <p className="mx-5">
            <span className="fw-bold">Responsable:</span> {movimiento.data?.nombreEmpleado}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table striped hover bordered>
            <thead>
              <tr>
                <th>NC</th>
                <th>Herramienta</th>
                <th>Tipo Herramienta</th>
                <th>Observaciones</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {movimiento.data?.registroHerramientas.length ? (
                <>
                  {movimiento.data.registroHerramientas.map((her: IHerramientaMovimiento) => (
                    <tr key={her.nc}>
                      <td>{her.nc}</td>
                      <td>{her.nombre}</td>
                      <td>{her.tipoHerramienta}</td>
                      <td>{her?.observacion || ''}</td>
                      <td>{her.estado}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {
                    <tr>
                      <td colSpan={5}>No hay herramientas en este prestamo.</td>
                    </tr>
                  }
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
