import { IEditarUsuario, IRegistroUsuario } from '../models/usuarios/IRegistroUsuario';
import http from '../utils/request/axios';

export const getUsuariosService = async () => {
  try {
    const response = await http.get('AdminUser/GetUsuarios');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getUsuariosInternosService = async () => {
  try {
    const response = await http.get('AdminUser/GetUsuariosInternosAD');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const getRolesService = async () => {
  try {
    const response = await http.get('AdminUser/GetRoles');
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addUsuarioIternoService = async (dataRequest: IRegistroUsuario) => {
  try {
    const response = await http.post('AdminUser/registraUsuarioInterno', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const addUsuarioExternoService = async (dataRequest: IRegistroUsuario) => {
  try {
    const response = await http.post('AdminUser/registraUsuarioExterno', dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const editUsuariosService = async (dataRequest: IEditarUsuario, idUser: string) => {
  try {
    const response = await http.put(`AdminUser/EditUser/${idUser}`, dataRequest);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const resetPasswordUsuarioExternoService = async (idUsuario: string) => {
  try {
    const response = await http.put(`AdminUser/resetPasswordUsuarioExterno/${idUsuario}`);
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};

export const activarUsuarioExternoService = async (idUsuario: string, estado: boolean) => {
  try {
    const response = await http.put(`AdminUser/activarUsuarioExterno/${idUsuario}`, { active: estado });
    return response?.data;
  } catch (ex) {
    throw ex;
  }
};
