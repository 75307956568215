import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo } from '@app/models/enums/catalogos';
import { IItemCertificacion } from '@app/models/herramienta/ICertificacion';
import { IItemMantenimiento } from '@app/models/herramienta/IMantenimiento';
import { getCatalogo } from '@app/services/catalogos.service';
import { useEffect, useState } from 'react';
import { Button, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import FormularioCertificaciones from '../FormularioCertificaciones';
import FormularioMantenimiento from '../FormularioMantenimiento';
import { ROLES } from '@app/models/enums';

export type ListaHerramientaModalProps = {
  estadoModal: boolean;
  cambiarEstadoModal: Function;
  agregarDataModal: Function;
  dataModal: {
    idHerramienta: number;
    nombreHerramienta: string;
    titulo: string;
    data: Array<IItemMantenimiento | IItemCertificacion>;
  };
};

export default function ListasHerramientaModal({
  estadoModal,
  cambiarEstadoModal,
  agregarDataModal,
  dataModal,
}: ListaHerramientaModalProps) {
  const roleUsuario = JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase();
  const [estadoTabs, setEstadoTabs] = useState<'lista' | 'formulario'>('lista');
  const [tiposMantenimiento, setTiposMantenimiento] = useState<ICatalogoGenerico[]>([]);

  const obtenerTipoMantenimiento = async () => {
    try {
      const response = await getCatalogo(
        dataModal.titulo === 'Mantenimientos' ? Catalogo.TiposDeMantenimiento : Catalogo.PeriodosDeCertificacion
      );
      setTiposMantenimiento(response);
    } catch (ex) {
      console.log(ex);
    }
  };

  const obtenerRespuestaFormulario = respuesta => {
    agregarDataModal(respuesta);
    setEstadoTabs('lista');
  };

  const formateoFecha = (fecha: string) => {
    if (fecha) {
      return fecha.slice(0, 10).split('-').reverse().join('/');
    }
    return '--/--/----';
  };

  useEffect(() => {
    if (estadoModal) {
      obtenerTipoMantenimiento();
    } else {
      setEstadoTabs('lista');
    }
  }, [estadoModal]);

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {dataModal.titulo} {dataModal.nombreHerramienta}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          className="mt-2 w-100"
          onSelect={(val: 'lista' | 'formulario') => setEstadoTabs(val)}
          activeKey={estadoTabs}
          transition={true}
        >
          <Tab className="mt-3" eventKey="lista" title={`Lista de ${dataModal.titulo.toLowerCase()}`}>
            <Table striped hover>
              <thead>
                <tr>
                  <th>
                    {dataModal.titulo === 'Mantenimientos'
                      ? `Tipo de ${dataModal.titulo.toLowerCase()}`
                      : 'Proveedor Externo'}
                  </th>
                  <th>Fecha {dataModal.titulo.toLowerCase()}</th>
                </tr>
              </thead>
              <tbody>
                {dataModal.data.length ? (
                  <>
                    {dataModal.data.map((val: any) => (
                      <tr key={val.id}>
                        <td>
                          {dataModal.titulo === 'Mantenimientos' ? (
                            <>
                              {
                                tiposMantenimiento.find(
                                  (item: ICatalogoGenerico) => item?.id === val.tipoDeMantenimientoId
                                )?.descripcion
                              }
                            </>
                          ) : (
                            <>{val?.responsable}</>
                          )}
                        </td>
                        <td>
                          {dataModal.titulo === 'Mantenimientos' ? (
                            <>{formateoFecha(val.ultimoMantenimiento)}</>
                          ) : (
                            <>{formateoFecha(val.ultimaCertificacion)}</>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={2}>{`No hay ${dataModal.titulo.toLowerCase()}`}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Tab>
          {dataModal.data.filter(
            (dat: IItemMantenimiento) =>
              dat.tipoDeMantenimientoId ===
              tiposMantenimiento.find(dt => dt.descripcion.toLowerCase() === 'correctivo')?.id
          ).length <= 4 &&
            ROLES.ADMINISTRADOR === roleUsuario && (
              <Tab className="mt-3" eventKey="formulario" title={`Agregar ${dataModal.titulo.toLowerCase()}`}>
                <FormularioMantenimiento
                  idHerramienta={dataModal.idHerramienta}
                  respuestaFormulario={obtenerRespuestaFormulario}
                />
              </Tab>
            )}
          {dataModal.titulo !== 'Mantenimientos' && ROLES.ADMINISTRADOR === roleUsuario && (
            <Tab className="mt-3" eventKey="formulario" title={`Agregar ${dataModal.titulo.toLowerCase()}`}>
              <FormularioCertificaciones
                idHerramienta={dataModal.idHerramienta}
                respuestaFormulario={obtenerRespuestaFormulario}
              />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
