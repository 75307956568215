import { ModalDestinatarioProps } from '@app/models/types/destinatarioPreferencias.type';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import {
  IAgregarDestinatarioPreferencias,
  ITipoNotificacion,
} from '@app/models/preferencias/IDestinatarioPreferencias';
import { useState, useEffect } from 'react';
import useError from '@app/hooks/useError';
import { nuevoDestinatarioValidaciones } from '@app/utils/validaciones/Preferencias.validaciones';
import { editarDestinatarioService, registrarDestinatarioService } from '@app/services/preferencias.service';
import { getCatalogo } from '@app/services';
import { Catalogo } from '@app/models/enums';

export default function DestinatarioPreferenciasModal(props: ModalDestinatarioProps) {
  const { estadoModal, cambiarEstadoModal, recuperarDestinatario, detalleDestinatario } = props;
  const [cargandoDestinatario, setCargandoDestinatario] = useState<boolean>(false);
  const [catalogoTipoNotificaciones, setCatalogoTipoNotificaciones] = useState<
    { id: number; tipo: string; checked: boolean }[]
  >([]);
  const [tipoNotificaciones, setTipoNotificaciones] = useState<{ id: number }[]>([]);
  const initialValues: IAgregarDestinatarioPreferencias = {
    nombreNotificado: '',
    puesto: '',
    emailNotificado: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: nuevoDestinatarioValidaciones,
    onSubmit: (values: IAgregarDestinatarioPreferencias) => guardarDestinatario(values),
  });
  const { ErrorAlert, setError } = useError();

  const guardarDestinatario = async (dataDestinatario: IAgregarDestinatarioPreferencias) => {
    try {
      setCargandoDestinatario(true);
      const requestDataDestinatario = {
        ...dataDestinatario,
        notificaciones: tipoNotificaciones,
      };
      const responseRegistrarDestinatario = detalleDestinatario
        ? await editarDestinatarioService(requestDataDestinatario, detalleDestinatario.id)
        : await registrarDestinatarioService(requestDataDestinatario);
      recuperarDestinatario(responseRegistrarDestinatario, !!detalleDestinatario);
      cambiarEstadoModal(false);
    } catch (ex) {
      setError(ex?.response?.data);
    } finally {
      setCargandoDestinatario(false);
    }
  };

  const notificacionSeleccionada = (notificacion: ITipoNotificacion, estadoCheckbox: boolean) => {
    if (estadoCheckbox) {
      setTipoNotificaciones((notis: { id: number }[]) => [...notis, { id: notificacion.id }]);
      setCatalogoTipoNotificaciones(catalogo => [
        ...catalogo.map(ct => (ct.id === notificacion.id ? { ...ct, checked: !ct.checked } : ct)),
      ]);
    } else {
      const tipoNotificacionesRemovidas = tipoNotificaciones.filter(
        (notif: { id: number }) => notif.id !== notificacion.id
      );
      setTipoNotificaciones(tipoNotificacionesRemovidas);
      setCatalogoTipoNotificaciones(catalogo => [
        ...catalogo.map(ct => (ct.id === notificacion.id ? { ...ct, checked: !ct.checked } : ct)),
      ]);
    }
  };

  const obtenerTipoNotificaciones = async () => {
    try {
      const response = await getCatalogo(Catalogo.TipoNotificacion);
      setCatalogoTipoNotificaciones(response.map(res => ({ ...res, tipo: res.descripcion, checked: false })));
    } catch (ex) {
      setError(ex?.response?.data);
    }
  };

  useEffect(() => {
    obtenerTipoNotificaciones();
  }, []);

  useEffect(() => {
    if (!!!estadoModal) {
      formik.resetForm();
      setTipoNotificaciones([]);
    } else {
      if (!!detalleDestinatario) {
        setTimeout(() => {
          formik.setFieldValue('nombreNotificado', detalleDestinatario.nombreNotificado);
          formik.setFieldValue('puesto', detalleDestinatario.puesto);
          formik.setFieldValue('emailNotificado', detalleDestinatario.emailNotificado);

          //i Se checkean las notificaciones que tenga el destinatario
          const todoTiposNotificaciones = catalogoTipoNotificaciones.map(tpnot => {
            const notificacion = detalleDestinatario.notificaciones.find(
              (notif: { id: number }) => notif.id === tpnot.id
            );
            if (!!notificacion) {
              return { ...tpnot, checked: true };
            }
            return { ...tpnot, checked: false };
          });
          setCatalogoTipoNotificaciones(todoTiposNotificaciones);
          setTipoNotificaciones(detalleDestinatario.notificaciones.map(tpnot => ({ id: tpnot.id })));
          formik.setErrors({});
        }, 200);
      } else {
        obtenerTipoNotificaciones();
      }
    }
  }, [estadoModal]);

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{detalleDestinatario ? 'Editar' : 'Agregar'} Destinatario</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <ErrorAlert />
          <Form.Group className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              name="nombreNotificado"
              isInvalid={!!formik.errors.nombreNotificado}
              onChange={formik.handleChange}
              value={formik.values.nombreNotificado}
              placeholder="Nombre"
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {formik.errors.nombreNotificado}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Puesto</Form.Label>
            <Form.Control
              name="puesto"
              isInvalid={!!formik.errors.puesto}
              onChange={formik.handleChange}
              value={formik.values.puesto}
              placeholder="Puesto"
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {formik.errors.puesto}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              name="emailNotificado"
              isInvalid={!!formik.errors.emailNotificado}
              onChange={formik.handleChange}
              value={formik.values.emailNotificado}
              placeholder="Correo"
            />
            <Form.Control.Feedback className="d-block" type="invalid">
              {formik.errors.emailNotificado}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tipo Notificación</Form.Label>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              {catalogoTipoNotificaciones.map((notificacion: { id: number; tipo: string; checked: boolean }) => (
                <Form.Check
                  className="my-2"
                  isInvalid={!!!tipoNotificaciones.length}
                  key={notificacion.id}
                  label={notificacion.tipo}
                  type="checkbox"
                  name="notificacion"
                  checked={notificacion.checked}
                  onChange={({ target }) =>
                    notificacionSeleccionada({ id: +target.value, tipo: notificacion.tipo }, target.checked)
                  }
                  value={notificacion.id}
                />
              ))}
            </div>
            <Form.Control.Feedback className="d-block" type="invalid">
              {!!!tipoNotificaciones.length && 'Debe seleccionar al menos un tipo de notificación'}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => cambiarEstadoModal(false)}>
            Cancelar
          </Button>
          <Button variant="secondary" type="submit" disabled={cargandoDestinatario || !!!tipoNotificaciones.length}>
            {cargandoDestinatario ? (
              <>
                Guardando &nbsp;
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </>
            ) : (
              'Guardar'
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
