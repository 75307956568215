import { IHerramienta } from '@app/models/herramienta/IHerramientaGeneral';
import { DetalleHerramientaProps } from '@app/models/types/detalleHerramienta.type';
import { Button, Col, Modal, Row } from 'react-bootstrap';

export default function DetalleHerramientaModal(props: DetalleHerramientaProps) {
  const { estadoModal, cambiarEstadoModal } = props;
  const herramienta = props.dataHerramienta || undefined;

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal((est: { estado: boolean, data: IHerramienta}) => ({...est, estado: false }))}>
      <Modal.Header closeButton>
        <Modal.Title>Detalle Herramienta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="fw-bold text-center mb-4">
          {herramienta?.herramientaGenerica}
        </h4>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <>
              { !!herramienta?.torque ? <p className='fw-bold'>Torque: <span className='fw-normal'>{herramienta?.torque}</span></p> : null}
              { !!herramienta?.unidadDeMedida ? <p className='fw-bold'>Unidad Medida: <span className='fw-normal'>{herramienta?.unidadDeMedida}</span></p> : null}
              { !!herramienta?.velocidadMotor ? <p className='fw-bold'>Velocidad: <span className='fw-normal'>{herramienta?.velocidadMotor}</span></p> : null}
              { !!herramienta?.compatibilidadCilindro ? <p className='fw-bold'>Compatibilidad Cilindro: <span className='fw-normal'>{herramienta?.compatibilidadCilindro}</span></p> : null}
              { !!herramienta?.capacidadDeposito ? <p className='fw-bold'>Capacidad Deposito: <span className='fw-normal'>{herramienta?.capacidadDeposito}</span></p> : null}
              { !!herramienta?.capacidaDepositioUnidad ? <p className='fw-bold'>Unidad Capacidad Deposito: <span className='fw-normal'>{herramienta?.capacidaDepositioUnidad}</span></p> : null}
              { !!herramienta?.flujoMaxPresionNominal ? <p className='fw-bold'>Flujo Máximo Presión Nominal: <span className='fw-normal'>{herramienta?.flujoMaxPresionNominal}</span></p> : null}
              { !!herramienta?.flujoMaximoPresionNominalUnidad ? <p className='fw-bold'>Unidad Flujo Máximo Presión Norminal: <span className='fw-normal'>{herramienta?.flujoMaximoPresionNominalUnidad}</span></p> : null}
              { !!herramienta?.esfuerzoMaxBombeo ? <p className='fw-bold'>Esfuerzo Máximo Bombeo: <span className='fw-normal'>{herramienta?.esfuerzoMaxBombeo}</span></p> : null}
              { !!herramienta?.esfuerzoMaximoBombeoUnidad ? <p className='fw-bold'>Unidad Esfuerzo Máximo Bombeo: <span className='fw-normal'>{herramienta?.esfuerzoMaximoBombeoUnidad}</span></p> : null}
            </>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cambiarEstadoModal((est: { estado: boolean, data: IHerramienta}) => ({...est, estado: false }))}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
