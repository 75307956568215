import Loading from '@app/components/Loading';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo } from '@app/models/enums';
import { IRegistrarCertificacion } from '@app/models/herramienta/ICertificacion';
import { ISummaryHerramientas } from '@app/models/herramienta/IHerramientaGeneral';
import { agregarCertificacion, getCatalogo, getHerramientas } from '@app/services';
import { nuevaCertificacionValidaciones } from '@app/utils/validaciones/certificacion.validaciones';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Certificacion() {
  const [periodosCertificacion, setPeriodosCertificacion] = useState<ICatalogoGenerico[]>([]);
  const [, setHerramientas] = useState<ISummaryHerramientas>({
    disponible: 0,
    prestada: 0,
    elementos: [],
  });
  const [, setHerramientaSeleccionada] = useState<ISummaryHerramientas>({
    disponible: 0,
    prestada: 0,
    elementos: [],
  });
  const [cargando, setCargando] = useState<boolean>(false);
  const { setError, ErrorAlert } = useError();
  const navigate = useNavigate();
  const initialValues: IRegistrarCertificacion = {
    fechaCertificacion: '',
    periodoCertificacion: 0,
    comentarios: '',
    proveedorId: 0,
    herramientaId: 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: nuevaCertificacionValidaciones,
    onSubmit: (values: IRegistrarCertificacion) => registrarCertificacion(values),
  });

  const obtenerPeriodosCertificacion = async () => {
    try {
      const response = await getCatalogo(Catalogo.PeriodosDeCertificacion);
      setPeriodosCertificacion(response);
    } catch (ex) {
      console.log(ex);
    }
  };

  const obtenerHerramientas = async () => {
    try {
      const data = await getHerramientas(0, 0);
      setHerramientas(data);
    } catch (ex) {
      console.log(ex);
    }
  };

  // const seleccionarHerramienta = response => {
  //   setHerramientaSeleccionada(response);
  //   if (response.length) {
  //     const [result] = response;
  //     formik.handleChange(result.modelo);
  //     formik.setValues(values => ({
  //       ...values,
  //       ultimaCertificacion: establecerUltimaCertificacion(result),
  //       herramientaId: `${result.id}`,
  //     }));
  //   } else {
  //     formik.handleChange('');
  //     formik.setValues(values => ({
  //       ...values,
  //       ultimaCertificacion: '',
  //       herramientaId: '0',
  //     }));
  //   }
  // };

  const registrarCertificacion = async (valores: IRegistrarCertificacion) => {
    try {
      setCargando(true);
      await agregarCertificacion(valores);
      navigate(AppRoutes.Herramienta);
    } catch (ex) {
      const { error } = ex;
      setError(error?.response?.data);
    } finally {
      setCargando(false);
    }
  };

  // const establecerUltimaCertificacion = herramienta => {
  //   const { certificaciones } = herramienta;
  //   if (certificaciones.length) {
  //     const { ultimaCertificacion } = certificaciones[certificaciones.length - 1];
  //     return ultimaCertificacion.slice(0, 10);
  //   } else if (herramienta.fechaCompra) {
  //     return herramienta.fechaCompra.slice(0, 10);
  //   }
  //   return '';
  // };

  useEffect(() => {
    obtenerPeriodosCertificacion();
    obtenerHerramientas();
  }, []);

  useEffect(() => {
    return () => {
      setPeriodosCertificacion([]);
      setHerramientas({
        disponible: 0,
        prestada: 0,
        elementos: [],
      });
      setHerramientaSeleccionada({
        disponible: 0,
        prestada: 0,
        elementos: [],
      });
      setCargando(false);
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      {cargando && <Loading mensaje="Registrando certificación" />}
      <ErrorAlert />
      <h1 className="text-center mb-5">Certificación</h1>
      <form onSubmit={formik.handleSubmit}>
        {/* <Row>
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Herramienta</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                labelKey="modelo"
                onChange={event => seleccionarHerramienta(event)}
                options={herramientas.elementos}
                placeholder="Selecciona una herramienta"
                selected={herramientaSeleccionada.elementos}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.herramientaId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row> */}
        <Row className="my-4">
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Responsable</Form.Label>
              <Form.Control
                name="responsable"
                isInvalid={!!formik.errors.proveedorId}
                value={formik.values.proveedorId}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={0}>Seleccione Responsable</option>
                <option value={1}>Primer Responsable</option>
                <option value={2}>Segundo Responsable</option>
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.proveedorId}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Periodo de Certificación</Form.Label>
              <Form.Control
                name="periodoCertificacion"
                isInvalid={!!formik.errors.periodoCertificacion}
                value={formik.values.periodoCertificacion}
                onChange={formik.handleChange}
                as="select"
              >
                <option value={0}>Seleccione Periodo de Certificación</option>
                {periodosCertificacion.map((periodo: ICatalogoGenerico) => (
                  <option key={periodo.id} value={periodo.id}>
                    {periodo.descripcion}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.periodoCertificacion}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* <Row className="my-4">
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Última Certificación</Form.Label>
              <Form.Control
                name="ultimaCertificacion"
                isInvalid={!!formik.errors.ultimaCertificacion}
                value={formik.values.ultimaCertificacion}
                onChange={formik.handleChange}
                type="date"
                disabled
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.ultimaCertificacion}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Form.Group>
              <Form.Label>Próxima Certificación</Form.Label>
              <Form.Control
                name="proximaCertificacion"
                isInvalid={!!formik.errors.proximaCertificacion}
                value={formik.values.proximaCertificacion}
                onChange={formik.handleChange}
                type="date"
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.proximaCertificacion}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row> */}
        <Row className="my-4">
          <Col md={12} sm={12} xs={12}>
            <Form.Group>
              <Form.Label>Comentarios</Form.Label>
              <Form.Control
                name="comentarios"
                isInvalid={!!formik.errors.comentarios}
                value={formik.values.comentarios}
                onChange={formik.handleChange}
                as="textarea"
                rows={5}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.comentarios}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="d-flex align-items-end justify-content-end" md={12} sm={12} xs={12}>
            <Button variant="secondary" type="submit">
              Guardar Certificación
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
