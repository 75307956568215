export enum CatalogosNombre {
  Estados = 'Estados',
  Ubicaciones = 'Ubicaciones',
  UnidadesDeMedida = 'Unidades de Medida',
  PeriodosDeCertificacion = 'Periodos de Certificación',
  PeriodoMantenimiento = 'Periodos de Mantenimiento',
  TiposDeMantenimiento = 'Tipos de Mantenimiento',
  TipoNotificacion = 'Tipos de Notificaciones',
  Proveedores = 'Proveedores',
  CompatibilidadesCilindro = 'Compatibilidades de Cilindro',
  TDeHerramienta = 'Tipos de Herramienta',
  Observaciones = 'Observaciones',
}

export enum Catalogo {
  Estados = 'Estados',
  Ubicaciones = 'Ubicaciones',
  UnidadesDeMedida = 'UnidadesDeMedida',
  PeriodosDeCertificacion = 'PeriodosDeCertificacion',
  PeriodoMantenimiento = 'PeriodoMantenimiento',
  TiposDeMantenimiento = 'TiposDeMantenimiento',
  TipoNotificacion = 'TipoNotificacion',
  Proveedores = 'Proveedores',
  CompatibilidadesCilindro = 'CompatibilidadesCilindro',
  TDeHerramienta = 'TDeHerramienta',
  Observaciones = 'Observaciones',
}
