import { ErrorMessages } from '@app/contants/errorMessages';
import { object, string } from 'yup';

export const herramientaGenericaSchema = object().shape({
  nombre: string().required(ErrorMessages.Required),
  inicialNc: string()
    .required(ErrorMessages.Required)
    .matches(/^[a-z]+$/i, ErrorMessages.OnlyLetters),
  periodoCertificacionId: string().required(ErrorMessages.Required),
  periodoMantenimientoId: string().required(ErrorMessages.Required),
  tipoHerramientaId: string().required(ErrorMessages.Required),
});
