import AgregarCatalogoModal from '@app/components/modals/AgregarCatalogoModal';
import EliminarModal from '@app/components/modals/EliminarModal';
import useError from '@app/hooks/useError';
import { ICatalogo } from '@app/models/catalogos/ICatalogo';
import { Catalogo, CatalogosNombre } from '@app/models/enums';
import { addCatalogo, deleteCatalogo, editCatalogo, getCatalogo } from '@app/services';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';

/* eslint-disable react-hooks/exhaustive-deps */
export default function Catalogos() {
  const [catalogo, setCatalogo] = useState<Catalogo>(Catalogo.Estados);
  const [estadoModal, setEstadoModal] = useState<boolean>(false);
  const [estadoEliminarModal, setEstadoEliminarModal] = useState<boolean>(false);
  const [datosCatalogo, setDatosCatalogo] = useState<ICatalogo[]>([]);
  const [detalleCatalogo, setDetalleCatalogo] = useState<ICatalogo>({ id: 0, descripcion: '', eliminado: false });
  const [opcionesCatalogos, setOpcionesCatalogos] = useState<string[]>([]);
  const { setError, ErrorAlert } = useError();

  const filtrarCatalogo = ({ target }) => {
    setError('');
    setCatalogo(target.value);
  };

  const obtenerDatosCatalogos = async () => {
    try {
      const response = await getCatalogo(catalogo);
      setDatosCatalogo(response);
    } catch (ex) {
      setError(ex?.response?.data);
    }
  };

  const editarCatalogo = (dataCatalogo: ICatalogo) => {
    setDetalleCatalogo(dataCatalogo);
    setEstadoModal(true);
  };

  const eliminarCatalogo = (dataCatalogo: ICatalogo) => {
    setDetalleCatalogo(dataCatalogo);
    setEstadoEliminarModal(true);
  };

  const edicionConfirmada = async (catalogoRecuperado: ICatalogo) => {
    try {
      const catalogoExiste = datosCatalogo.some(item => item.id === catalogoRecuperado.id);
      if (catalogoExiste) {
        const response = await editCatalogo(catalogo, catalogoRecuperado);
        const catalogosActualizados = datosCatalogo.map((item: ICatalogo) =>
          item.id === response.id ? response : item
        );
        setDatosCatalogo([...catalogosActualizados]);
      } else {
        const response = await addCatalogo(catalogo, catalogoRecuperado);
        setDatosCatalogo(cats => [...cats, response]);
      }
    } catch (ex) {
      setError(ex?.response?.data);
    }
  };

  const eliminacionConfirmada = async (nombreCatalogo: Catalogo) => {
    try {
      const { id } = detalleCatalogo;
      await deleteCatalogo(nombreCatalogo, id);
      const indiceItem = datosCatalogo.findIndex(dat => dat.id === detalleCatalogo.id);
      datosCatalogo.splice(indiceItem, 1);
      setEstadoEliminarModal(false);
    } catch (ex) {
      setError(ex?.response?.data);
    }
  };

  useEffect(() => {
    obtenerDatosCatalogos();
  }, [catalogo]);

  useEffect(() => {
    if (!estadoEliminarModal && !estadoModal) {
      setDetalleCatalogo({ id: 0, descripcion: '', eliminado: false });
    }
  }, [estadoEliminarModal, estadoModal]);

  useEffect(() => {
    const opcionesCatalogosFiltrados = Object.values(Catalogo).filter(
      (cat: string) => !cat.includes('Tipo') && !cat.includes('Periodo')
    );
    setOpcionesCatalogos(opcionesCatalogosFiltrados);
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <ErrorAlert />
      <h1 className="text-center mb-5">Catálogos</h1>
      <Row className="mb-5">
        <Col md={9} sm={9} xs={9}>
          <Form.Group className="w-50">
            <Form.Label>Catálogos</Form.Label>
            <Form.Control
              as={Form.Select}
              name="catalogo"
              onChange={(event: any) => filtrarCatalogo(event)}
              value={catalogo}
            >
              {opcionesCatalogos.map(val => (
                <option key={Catalogo[val]} value={Catalogo[val]}>
                  {CatalogosNombre[val]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="d-flex align-items-end justify-content-end" md={3} sm={3} xs={3}>
          <Button variant="secondary" onClick={() => setEstadoModal(true)}>
            Agregar Catálogo
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Table striped hover>
            <thead>
              <tr>
                {catalogo === Catalogo.Proveedores && <th>Código de Proveedor</th>}
                <th>Descripción</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {!!datosCatalogo.length ? (
                <>
                  {datosCatalogo.map((cat: ICatalogo) => (
                    <tr key={cat.id}>
                      {catalogo === Catalogo.Proveedores && <td>{cat.id}</td>}
                      <td>{cat.descripcion}</td>
                      <td className="d-flex align-items-center">
                        <IconButton style={{ width: '40px', height: '40px' }}>
                          <EditIcon className="d-block mx-2" onClick={() => editarCatalogo(cat)} />
                        </IconButton>
                        <IconButton style={{ width: '40px', height: '40px' }}>
                          <DeleteIcon className="d-block mx-2" onClick={() => eliminarCatalogo(cat)} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={2}>No hay catálogos registrados</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <AgregarCatalogoModal
        estadoModal={estadoModal}
        cambiarEstadoModal={setEstadoModal}
        tituloModal={`${!!detalleCatalogo.id ? 'Editar' : 'Agregar'} ${CatalogosNombre[catalogo]}${
          +catalogo === 3 ? ' de Medida' : ''
        }`}
        dataModal={detalleCatalogo}
        respuestaModal={edicionConfirmada}
        catalogo={catalogo}
      />
      <EliminarModal
        estadoModal={estadoEliminarModal}
        cambiarEstadoModal={setEstadoEliminarModal}
        tituloModal={`Eliminar ${CatalogosNombre[catalogo]}${+catalogo === 3 ? ' de Medida' : ''}`}
        nombreItemModal={detalleCatalogo.descripcion}
        respuestaModal={() => eliminacionConfirmada(catalogo)}
      />
    </Container>
  );
}
