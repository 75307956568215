export default function Footer() {
  return (
    <footer className="d-flex justify-content-center" style={{ fontSize: '0.625rem' }}>
      <p className="text-center col-10 border-top p-2">
        <span className="me-2">&copy; {new Date().getFullYear()}</span> Consorcio Minero Benito Juárez Peña Colorada,
        S.A. de C.V.
      </p>
    </footer>
  );
}
