import { IndicadoresColoresMantenimiento } from '@app/models/enums';

export default function IndicadoresMantenimiento() {
  const estilos = {
    parrafo: {
      fontSize: '14px',
    },
    indicador: {
      width: '15px',
      height: '15px',
      borderRadius: '50%',
    },
  };

  return (
    <>
      <h4 className="text-center mt-0 mb-2">Indicadores de Mantenimiento Preventivo</h4>
      <div className="d-flex align-items-center justify-content-around w-75 my-4 mx-auto ">
        <div className="d-flex align-items-center">
          <div style={{ ...estilos.indicador, backgroundColor: IndicadoresColoresMantenimiento.Estable }} />
          <p className="fw-bold my-0 mx-2" style={estilos.parrafo}>
            Mantenimiento Estable
          </p>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ ...estilos.indicador, backgroundColor: IndicadoresColoresMantenimiento.Proximo }} />
          <p className="fw-bold my-0 mx-2" style={estilos.parrafo}>
            Mantenimiento Próximo
          </p>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ ...estilos.indicador, backgroundColor: IndicadoresColoresMantenimiento.Urgente }} />
          <p className="fw-bold my-0 mx-2" style={estilos.parrafo}>
            Mantenimiento Urgente
          </p>
        </div>
      </div>
    </>
  );
}
